//================================================================
//  Application: Application Management Console
//================================================================

//  Product Owner: Shaun Brooks
//  Created 30/10/23

//================================================================

//Libraries
import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts
import { SetAppSize, GetAppSize } from './Library/GlobalContexts.js';

//Pages
import Home from './Pages/Home/Home.js';
import Purchases from './Pages/Purchases/Purchases.js';
import Support from './Pages/Support/Support.js';
import RequestNewSoftware from './Pages/Support/Components/RequestNewSoftware/RequestNewSoftware.js';
import Account from './Pages/Mobile/Account.js';
import Requests from './Pages/Account/Requests/Requests.js';
import RequestDetails from './Pages/Account/Requests/RequestDetails.js';
import Approvals from './Pages/Account/Approvals/Approvals.js';
import ApprovalDetails from './Pages/Account/Approvals/ApprovalDetails.js';
import Catalogue from './Pages/Catalogue/Catalogue.js';
import CatalogueDetail from './Pages/Catalogue/CatalogueDetail.js';

//Pages - Mobile View
import ApprovalsM from './Pages/Mobile/Approvals/ApprovalsM.js';
import ApprovalDetailsM from './Pages/Mobile/Approvals/ApprovalDetailsM.js';

//Components
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer.js';
import GlobalToast from './Components/GlobalToast/GlobalToast.js';

//Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getAppSize = useContext(GetAppSize);
  const setAppSize = useContext(SetAppSize);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  function handleResize(e) {

    setAppSize({
      'width': e.currentTarget.innerWidth,
      'height': e.currentTarget.innerHeight
    });

  };

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

  // onLoad
  // 1. Setup an event listener to get width and height of app
  useEffect(() => {

    setAppSize({
      'width': window.innerWidth,
      'height': window.innerHeight
    });

    window.addEventListener('load', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('load', handleResize)
      window.removeEventListener('resize', handleResize)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className='App-Container'>

      {/* Nav */}
      <div className='App-Container-Nav'>
        <Navbar></Navbar>
      </div>

      {/* Body > Contains all pages in the app  */}
      <div className='App-Container-Body'>

        <GlobalToast></GlobalToast>

        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/store/catalogue' element={<Catalogue />} />
          <Route path='/store/catalogue/:id' element={<CatalogueDetail />} />
          <Route path='/purchases' element={<Purchases />} />
          <Route path='/support' element={<Support />} />
          <Route path='/support/digitalbusinessimprovementform' element={<RequestNewSoftware />} />
          <Route path='/requests' element={<Requests />} />
          <Route path='/requests/:id' element={<RequestDetails />} />
          <Route path='/approvals' element={
            getAppSize.width > 768 ?
              <Approvals />
              :
              <ApprovalsM />
          } />

          <Route path='/approvals/:id' element={
            getAppSize.width > 768 ?
              <ApprovalDetails />
              :
              <ApprovalDetailsM />
          } />
          <Route path='/account' element={<Account />} />

        </Routes>

        <Footer></Footer>

      </div>

    </div>
  )

  //------------------------------------------------------
}