//================================================================
//  Component: Action Menu
//================================================================

//  Purpose: Dropdown menu for Bulk Approvals

//  Properties:
//    - requestList = {useState (Array), list of tasks to approve/reject}
//    - setRequestList = {useState, use this to reset the requestList after form is submitted}
//    - isDisabled = {bool, determine whether button is disabled}

//  Example:
//    <ActionMenu
//      requestList={requestList}
//      setRequestList={setRequestList}
//      isDisabled={requestList.length === 0}
//     ></ActionMenu>     

//================================================================


//Libraries
import React, { useState } from 'react';

//Components
import ActionPane from '../ActionPane/ActionPane';

//Images
import ArrowIcon from '../Images/Icon_Expand_White.svg';

export default function ActionMenu({
    requestList,
    setRequestList,
    isDisabled
}) {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to toggle visibility of the kebab dropdown menu 
    const [showDropdown, setShowDropdown] = useState(true);

    // Sets bulk approval pane
    const [paneData, setPaneData] = useState([]);

    // Set status > 'Completed', 'Rejected'
    const [status, setStatus] = useState('');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    function actionHandler(status) {

        // Close the action menu and open the sidepane
        setShowDropdown(false);

        setStatus(status);

        setPaneData(requestList);

    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------



    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <>
            <button className='Dropdown-Button relative flex flex-row justify-between items-center' disabled={isDisabled} onClick={() => setShowDropdown(!showDropdown)}>
                Action
                <img alt='Expand' src={ArrowIcon} />

                {/* Dropdown */}
                <div className='flex flex-col absolute font-normal min-w-[200px] -ml-[112px] text-black bg-white shadow-md z-10 top-[35px] border border-[#D8D8D8] transition-all' hidden={showDropdown}>
                    <label className='hover:bg-[#E3F1FB] text-left p-2' onClick={() => actionHandler('Completed')}>Approve</label>
                    <label className='hover:bg-[#E3F1FB] text-left p-2'>Reassign</label>
                    <label className='hover:bg-[#E3F1FB] text-left p-2 text-red-700' onClick={() => actionHandler('Rejected')}>Reject</label>
                </div>
            </button>

            {/* ----------------------------------- */}
            {/*  Side Panes                         */}
            {/* ----------------------------------- */}

            <ActionPane
                requestDoc={paneData}
                setRequestDoc={setPaneData}
                requestStatus={status}
                setRequestList={setRequestList}
            > </ActionPane>
        </>
    )
}
