//================================================================
//  Component: Navigation Item
//================================================================

//  Purpose: This is the standard for adding a navigation link to the nav bar

//  Properties:
//    - style = <String> - CSS class > determines if the link is active/inactive
//    - link = <String> - url/route
//    - title = <String> - display name for navigation link

//  Example:
//    <NavItem 
//      style={changeStyleHandler('/projects')} 
//      link={'/projects'} 
//      title={'Projects'} 
//    ></NavItem>

//================================================================


//Libraries
import React from 'react';
import { Link } from 'react-router-dom';

//Contexts

//Functions


export default function NavItem({
  style,
  link, 
  title,
}) {

  //------------------------------------------------------
  //  Returned HTML
  //------------------------------------------------------
 
    // Check user role > display nav link

    return (
      <Link className={style} to={link}>
        <div className='no-underline px-2'>
          {title}
        </div>
      </Link>
    )
  ;

  //------------------------------------------------------
}