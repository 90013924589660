//================================================================
//  Component: User Search
//================================================================

//  Purpose: This is an input field that allows you to search all LL Users and select a user

//  Properties:
//    - dataField = {useReducer Field, string of the field name}
//    - required = {boolean, this determines if this field is required}
//    - register = {react hooks forms state, connects the field to the form state}
//    - setValue = {react hooks form state, programmatically sets the value for a field}
//    - errors = {react hooks form state, stores the errors for the field}

//  Example:
//    <UserSearch
//      dataField={'financemanager'}
//      required={true}
//      register={register}
//      setValue={setValue}
//      errors={errors.financemanager}
//    ></UserSearch>    

//================================================================


//Libraries
import React, { useReducer } from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Images

export default function UserSearch({
    dataField,
    required,
    register,
    setValue,
    errors
}) {

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store the search data
    const [searchData, setSearchData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
          'newUserInput': '',
          'queryMessage': '',
          'searchResults': []
        }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Function used to handle search
    const handleSearch = (value) => {

        setSearchData({
            'searchResults': [],
            'newUserInput': value,        
        })

        // Validate input
        if (searchData?.newUserInput?.length === 0) {
            return setSearchData({ 'queryMessage': 'Please enter an email address.' });

        }

        if (searchData?.newUserInput?.length === 2) {
            return setSearchData({ 'queryMessage': 'Search requires more than two characters.' });

        }

        const query = searchData?.newUserInput?.toLowerCase();

        // Search by characters in email
        return QueryDocument('users', [
            ['searcharray', 'array-contains-any', [query]], 
            ['usertype', '==', 'lendleaseuser']
        ])
        .then((users) => {

            setSearchData({
                'searchResults': users,
                'queryMessage': `Showing ${users?.length} result(s)`
            });

        })
        .catch((error) => {

            console.log(error);
            setSearchData({ 'queryMessage': 'Oops something went wrong!' });

        });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <div className='flex flex-col w-full h-full relative'>

            {/* ------------------------------------------------------ */}
            {/*  Users Search Bar                                      */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center'>

                {/* Search Input Field */}
                <input
                    className={
                        errors ?
                        'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border-[1.5px] border-solid border-[#DE0000] rounded-[5px]'
                        : searchData?.searchResults?.length > 0 ?
                        'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none'
                        :
                        'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-[5px]'
                    }
                    type='text'
                    placeholder='Search user by email address'
                    {...register(dataField, { 
                        required: required ? 'This field is required' : false,
                    })}
                    onChange={(e) => handleSearch(e.target.value)}
                    onPaste={(e) => handleSearch(e.clipboardData.getData('Text'))}
                    autoComplete='off'
                ></input>
            
            </div>

            {/* Search Results */}
            {
            searchData?.searchResults?.length > 0 &&
            (
                <div className='absolute w-full z-10 top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>
                    
                    {/* Results */}
                    {
                        searchData?.searchResults.map((user, index) => (
                        <div 
                            key={index} 
                            className='flex flex-row justify-between px-4 py-[13px] cursor-pointer rounded-sm hover:bg-[#F0F0F0]' 
                            onClick={() => {
                                // Save the email address and the name
                                setValue(dataField, user?.emailaddress);
                                setValue(`${dataField}givenname`,user?.givenname)
                                setValue(`${dataField}surname`,user?.surname)

                                // Update the search results
                                setSearchData({ 
                                    'searchResults': [],
                                    'newUserInput': user?.emailaddress,
                                });
                            }}
                        >
            
                            {/* User Detail */}
                            <div className='flex flex-col'>
                                <p className='m-0 font-medium text-[14px]'>{user?.givenname} {user?.surname}</p>
                                <p className='m-0 text-[14px]'>{user?.emailaddress} ({user?.jobtitle})</p>
                            </div>
                        
                        </div>
                        ))
                    }
                    
                    {/* Total */}
                    <div className='text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData.queryMessage.length === 0}>
                        {searchData.queryMessage}
                    </div>

                </div>
            )
            }

            {/* Error Message */}
            {errors && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors?.message}</p>}

            
        </div>
    )

  //------------------------------------------------------
}
