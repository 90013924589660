//================================================================
//  Component: Manage Approval
//================================================================

//  Purpose: Sequence of approval management pages for Mobile view

//  Properties:
//    - requestDoc = {Object, contains approval details}

//  Example:
//  <ManageApproval requestDoc={requestDoc} /> 

//================================================================


//Libraries
import React, { useState, useContext } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../Library/WriteDocument';

//Images
import ArrowIcon from '../../Components/Images/Icon_LeftArrow_Black.svg';
import TickIcon from '../../Components/Images/Icon_Approved_Green.svg';
import ErrorIcon from '../../Components/Images/Icon_Error_Red.svg'

export default function ManageApproval({ requestDoc }) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Stages for different pages > 'pre-selection', 'selection', 'post-selection', 'error'
  const [stage, setStage] = useState('pre-selection');

  // For changing request status dropdown > 'Approve', 'Reassign', 'Reject'
  const [requestStatus, setRequestStatus] = useState('');

  // Some status changes require an additional comment
  const [changeStatusComment, setChangeStatusComment] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Disables the "Proceed" button if conditions aren't met
  function isSubmitDisabled() {

    if (getUser?.emailaddress !== requestDoc?.assignedto?.email) return true;

    if (requestStatus === 'Approve') {
      return false;
    }

    // if (requestStatus === 'Reassign') {
    //   return selectedUser?.newUserInput && changeStatusComment.length > 0 ? false : true;
    // }

    if (requestStatus === 'Reject') {
      return changeStatusComment.length > 0 ? false : true;
    }

    return true;

  }

  // Submits form
  function handleSubmit() {

    if (requestStatus === 'Approve') {

      const newActivityFeed = [...requestDoc.activityfeed, {
        'action': 'approved',
        'actionedby': {
          'email': getUser?.emailaddress,
          'givenname': getUser?.givenname,
          'surname': getUser?.surname,
        },
        'activitydate': new Date(),
        'comments': changeStatusComment
      }]

      const docObject = {
        'status': 'Completed',
        'activityfeed': newActivityFeed,
      }

      return WriteDocument('tasks', requestDoc?.taskid, docObject, true)
        .then(() => {
          setRequestStatus('');
          setChangeStatusComment('');
          setStage('post-selection');
        })
        .catch((err) => {
          console.log(err);
          setStage('error');
        })
    }

    // if (requestStatus === 'Reassign') {

    //     const newActivityFeed = [...requestDoc.activityfeed, {
    //         'action': 'reassigned',
    //         'actionedby': {
    //             'email': getUser?.emailaddress,
    //             'givenname': getUser?.givenname,
    //             'surname': getUser?.surname,
    //         },
    //         'activitydate': new Date(),
    //         'comments': changeStatusComment
    //     }]

    //     const docObject = {
    //         'activityfeed': newActivityFeed,
    //         'assignedto': {
    //             'email': selectedUser?.newUserInput,
    //             'givenname': selectedUser?.newGivenname,
    //             'surname': selectedUser?.newSurname,
    //         }
    //     }

    //     return WriteDocument('tasks', requestDoc?.taskid, docObject, true)
    //         .then(() => {
    //             setRequestStatus('');
    //             setChangeStatusComment('');
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             //TODO add toast
    //         })
    // }

    if (requestStatus === 'Reject') {

      const newActivityFeed = [...requestDoc.activityfeed, {
        'action': 'rejected',
        'actionedby': {
          'email': getUser?.emailaddress,
          'givenname': getUser?.givenname,
          'surname': getUser?.surname,
        },
        'activitydate': new Date(),
        'comments': changeStatusComment
      }]

      const docObject = {
        'status': 'Rejected',
        'activityfeed': newActivityFeed,
      }

      return WriteDocument('tasks', requestDoc?.taskid, docObject, true)
        .then(() => {
          setRequestStatus('');
          setChangeStatusComment('');
          setStage('post-selection');
        })
        .catch((err) => {
          console.log(err);
          setStage('error')
        })
    }

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Returns nothing if request has already been approved/rejected in the past.
  if (requestDoc?.status !== 'In Progress' && stage !== 'post-selection') return;

  return (
    <>

      {/* =========================================================== */}
      {/* PRE-SELECTION STAGE: ONLY SHOW BUTTON                       */}
      {/* =========================================================== */}

      <div className='absolute flex bottom-0 bg-white border-t border-neutral-300 w-full h-16 z-10' hidden={stage !== 'pre-selection'}>
        <button className='Submit-Button m-auto w-[80%]' onClick={() => {
          setStage('selection');
          setRequestStatus('');
        }}
        >
          Manage Approval
        </button>
      </div>

      {/* =========================================================== */}
      {/* SELECTION STAGE                                             */}
      {/* =========================================================== */}

      <div className='absolute flex flex-col h-[calc(100vh-70px)] w-full top-[70px] z-10 bg-white' hidden={stage !== 'selection'}>

        <div className='flex flex-col h-full justify-between'>

          <div>

            {/* HEADER */}
            <div className='flex flex-row p-3 border-b-[1px] border-neutral-300'>
              {/* Back: Returns to previous screen, does not clear Text field */}
              <img src={ArrowIcon} alt='Back' className='h-6' onClick={() => { setStage('pre-selection') }} />
              <h5 className='m-auto'>Manage Approval</h5>
            </div>

            {/* BODY */}
            <div className='flex flex-col gap-4 p-3'>
              <label className='font-semibold'>Change status:</label>
              <select
                className='Input-Field-Select'
                value={requestStatus}
                disabled={requestDoc?.status !== 'In Progress'}
                onChange={(e) => setRequestStatus(e.target.value)}
              >
                {/* If Completed, show as "Approved", otherwise show "Rejected". If pending approval, show "Select" */}
                <option hidden value=''>Select</option>
                <option value='Approve'>Approve</option>
                {/* <option value='Reassign'>Reassign</option> */}
                <option className='text-[#DE0000]' value='Reject'>Reject</option>
              </select>

              <div hidden={requestStatus.length === 0} className='flex flex-col gap-2'>
                <label className='font-semibold'> Comment: {requestStatus !== 'Approve' && <span className='text-[#C4314B]'>*</span>} </label>
                <textarea
                  className='Input-Field-TextArea'
                  placeholder={`${requestStatus === 'Approve' ? 'Add a comment...' : 'Provide a reason for rejecting this request...'}`}
                  value={changeStatusComment}
                  onChange={(e) => setChangeStatusComment(e.target.value)}
                ></textarea>
              </div>

            </div>

          </div>

          {/* FOOTER */}
          <div className='border-t-2 py-3 px-4 flex flex-row gap-3 justify-center'>

            <button className='Primary-Button w-[150px]' disabled={isSubmitDisabled()} onClick={() => handleSubmit()}>
              Proceed
            </button>

            {/* Cancel: Returns to previous screen, clears the Text field. */}
            <button className='Secondary-Button w-[150px]' onClick={() => {
              setRequestStatus('');
              setChangeStatusComment('');
              setStage('pre-selection');
            }}>
              Cancel
            </button>

          </div>

        </div>

      </div>

      {/* =========================================================== */}
      {/* POST-SELECTION STAGE: SHOW SUCCESS TOAST                    */}
      {/* =========================================================== */}
      <div className='absolute flex flex-col justify-between w-full h-1/4 rounded-lg bg-white shadow-lg z-10 bottom-0 p-3' hidden={stage !== 'post-selection'}>

        <div className='flex flex-row gap-2 p-3 bg-[#e7f2da] rounded-md'>
          <img src={TickIcon} alt='Success'></img>
          <label>Successfully updated request.</label>
        </div>

        <div className='Submit-Button' onClick={() => setStage('pre-selection')}>
          Close
        </div>

      </div>

      {/* =========================================================== */}
      {/* ERROR: SHOW FAILURE TOAST                                   */}
      {/* =========================================================== */}
      <div className='absolute flex flex-col justify-between w-full h-1/4 rounded-lg bg-white shadow-lg z-10 bottom-0 p-3' hidden={stage !== 'error'}>

        <div className='flex flex-row gap-2 p-3 bg-[#fcf4f6] rounded-md'>
          <img src={ErrorIcon} alt='Error'></img>
          <label>Oops, something went wrong. Please contact the Service Desk for assistance.</label>
        </div>

        <div className='Submit-Button' onClick={() => setStage('pre-selection')}>
          Close
        </div>

      </div>


    </>

  )
}
