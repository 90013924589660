//================================================================
//  Page: ApprovalDetailsM
//================================================================

//  Purpose: Detailed Approval page - shows individual approval in detail (Mobile View)

//================================================================

//Libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadCrumbs';
import TabView from '../../../Components/TabView/TabView';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import HistoryTimeline from '../../../Components/HistoryTimeline/HistoryTimeline';
import CommentsThread from '../../../Components/CommentsThread/CommentsThread';
import ManageApproval from '../../../Components/ManageApproval/ManageApproval';

//Functions
import DocumentListener from '../../../Library/DocumentListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import FileIcon from '../../../Components/Images/Icon_File_Black.svg';
// import LinkIcon from '../../../Components/Images/Icon_OpenNewWindow_Blue.svg'; - to be added later

//CSS


export default function ApprovalDetailsM() {

    //------------------------------------------------------
    //  useContexts & React Router
    //------------------------------------------------------

    const params = useParams();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to determine status of the page > 'pending', 'onload', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Contains Firestore Document of the request
    const [requestDoc, setRequestDoc] = useState(undefined);

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get the approval document
    useEffect(() => {

        const taskid = params.id;

        function onLoadChange(document) {

            setRequestDoc(document);

            setPageStatus('onload');

        }

        function onError(error) {
            console.log(error);

            setPageStatus('error-fatal');
        }

        const unsubscribe = DocumentListener('tasks', taskid, onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        }

    }, [params])

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <PageComponent
            status={pageStatus}
            header={
                <div className='flex flex-col p-4 bg-neutral-100 h-32 w-full border-solid border-1 border-neutral-300'>
                    <BreadCrumbs rootLinkName={'My Approvals'} rootLinkRoute={'/approvals'} childLinkName={requestDoc?.requestid?.toUpperCase()} />
                    <h4 className='flex items-center h-full cursor-default'>{requestDoc?.taskname}</h4>
                </div>}
            body={
                <div className='flex flex-col'>

                    {/* MAIN PAGE CONTENT-REQUEST INFORMATION */}
                    <div className='p-8'>

                        <div>

                            {/* Request Name */}
                            <label className='font-semibold h-12 flex items-center'>Name</label>
                            <p className='h-12 flex items-center'>{requestDoc?.taskname}</p>

                            {/* Short Description */}
                            <label className='font-semibold h-12 flex items-center'>Short Description</label>
                            <p className='h-12 flex items-center'>{requestDoc?.taskdescription}</p>

                            {/* Assigned To */}
                            <label className='font-semibold h-12 flex items-center'>Assigned To</label>
                            <div className='flex flex-col h-24 py-4'>
                                <p className='h-2 flex items-center'>{requestDoc?.assignedto?.givenname} {requestDoc?.assignedto?.surname}</p>
                                <p className='h-2 flex items-center text-sm text-[#424242]'>{requestDoc?.assignedto?.email}</p>
                            </div>

                            {/* Status */}
                            <label className='font-semibold h-12 flex items-center'>Status</label>
                            <StatusLabel status={requestDoc?.status} />

                            {/* Created Date */}
                            <label className='font-semibold h-12 flex items-center'>Created Date</label>
                            <p className='h-12 flex items-center'>{ConvertDate(requestDoc?.createddate)}</p>

                            {/* Uploaded Documents */}
                            <label className='font-semibold h-12 flex items-center'>Uploaded Documents</label>
                            <div className='flex flex-col min-h-12 border-1 rounded-md'>
                                {
                                    requestDoc?.supportingdocs && requestDoc?.supportingdocs?.length > 0 ?
                                    requestDoc?.supportingdocs?.map((doc, index) => (
                                        <div key={index} className='flex flex-row'>
                                            <img className='h-6 my-auto mx-2' alt='File' src={FileIcon} />
                                            <a className='h-12 flex items-center cursor-pointer' target='_blank' rel='noopener noreferrer' href={doc?.fileUrl}>{doc?.fileName}</a>
                                        </div>
                                    ))
                                    :
                                    <p className='my-auto mx-2'>N/A</p>
                                }
                            </div>

                            {/* TODO */}
                            {/* <div className=' flex flex-row gap-2 h-24 items-center'>
                                <a href=''>View original request</a>
                                <img src={LinkIcon} alt='Open in new tab' className='h-4'></img>
                            </div> */}

                        </div>


                    </div>

                    <hr className='m-0 text-neutral-500'></hr>

                    <TabView
                        defaultView={1}

                        // History Tab
                        oneTitle={'History'}
                        oneContent={
                            requestDoc?.activityfeed?.toReversed().map((item, index) => (
                                <HistoryTimeline
                                    item={item}
                                    key={index}
                                ></HistoryTimeline>
                            ))

                        }

                        // Notes Tab
                        twoTitle={'Notes'}
                        twoTag={requestDoc?.comments?.length}
                        twoContent={
                            <div className='p-4'>
                                <CommentsThread task={requestDoc}></CommentsThread>
                            </div>
                        }
                    ></TabView>

                    {/* MANAGE APPROVAL - Only shows on pending approvals */}
                    <ManageApproval requestDoc={requestDoc}></ManageApproval>

                </div>}
        ></PageComponent>
    )
}