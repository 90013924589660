//================================================================
//  Component: Action Pane
//================================================================

//  Purpose: Sidepane to Approve/Reject/Reassign requests in bulk

//  Properties:
//      requestDoc = {useState, contains request data}
//      setRequestDoc = {useState, to reset after submission}
//      requestStatus = {useState, contains status ('Completed' or 'Rejected')}
//      setRequestList = {useState, to reset after submission}

//  Example:
//    <ActionPane
//      requestDoc={paneData}
//      setRequestDoc={setPaneData}
//      requestStatus={status}
//      setRequestList={setRequestList}
//    > </ActionPane>

//================================================================

//Libraries
import React, { useState, useEffect, useContext } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';
import { SetToast } from '../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../Library/WriteDocument';

//Images
import LoadingIcon from '../Images/Image_Loading_Ripple.svg';
import CloseIcon from '../Images/Close_Icon_Gray.svg'


export default function ActionPane({
    requestDoc,
    setRequestDoc,
    requestStatus,
    setRequestList
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to determine the status of the pane > 'pending', 'onload'
    const [paneStatus, setPaneStatus] = useState('pending');

    // Add comment to status change
    const [comment, setComment] = useState('');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    function handleSubmit() {

        setPaneStatus('pending');

        const docWritePromises = [];

        let activityAction;

        // For activity feed log
        if (requestStatus === 'Completed') activityAction = 'approved';
        else if (requestStatus === 'Rejected') activityAction = 'rejected';
        else activityAction = 'reassigned';

        requestDoc.forEach((request) => {

            const newActivityFeed = [...request.activityFeed, {
                'action': activityAction,
                'actionedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'activitydate': new Date(),
                'comments': comment
            }];

            const docObject = {
                'status': requestStatus,
                'activityfeed': newActivityFeed,
                'lastmodifiedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'lastmodifieddate': new Date(),
            };

            docWritePromises.push(WriteDocument('tasks', request.taskId, docObject, true))
        })

        // Settle promises
        Promise.all(docWritePromises)
            .then(() => {

                // Reset and close side pane
                setRequestDoc([]);
                setRequestList([]);
                setComment('');

                // Show successful toast
                setToast({
                    'type': 'success',
                    'message': 'Successfully updated the request(s).'
                })

            }).catch((error) => {
                console.log('error', error);

                // Show error toast
                setToast({
                    'type': 'error',
                    'message': 'Failed to save changes.'
                })
            });

    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Onload
    useEffect(() => {

        // Close the pane if there are issues
        if (requestDoc.length === 0) return;
        if (paneStatus === 'onload') return;

        setPaneStatus('onload');

        // eslint-disable-next-line
    }, [requestDoc]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (requestDoc.length === 0) return null;

    //====================================================
    //  Pending
    //====================================================

    if (paneStatus === 'pending') {
        return (
            <div className='Pane-Background'>
                <dialog className='Pane-Container flex flex-col gap-2 pb-0 items-center'>

                    <img className='w-[200px] self-center' alt='loading-circle-icon' src={LoadingIcon}></img>

                    <button className='Primary-Button w-fit self-center' onClick={() => {

                        setRequestDoc([]);
                        setPaneStatus('pending');

                    }}>
                        Cancel
                    </button>

                </dialog >
            </div>
        )
    }

    //====================================================
    //  Onload
    //====================================================

    return (
        <div className='Pane-Background'>
            <dialog className={`flex flex-col gap-2 pb-0 ${requestStatus === 'Rejected' ? 'Pane-Container-Red' : 'Pane-Container'}`}>

                {/* ------------------------------------------------------ */}
                {/*  Header Container                                      */}
                {/* ------------------------------------------------------ */}

                <div className='flex flex-col'>

                    {/* ------------------------------------------------------ */}
                    {/*  Header                                                */}
                    {/* ------------------------------------------------------ */}

                    <div className='flex flex-col'>

                        <div className='flex flex-row gap-2 p-4'>

                            <h4>Approval Status</h4>

                        </div>

                        {/* Border - Bottom */}
                        <hr className='m-0'></hr>

                    </div>

                </div>

                {/* ------------------------------------------------------ */}
                {/*  Body Container                                        */}
                {/* ------------------------------------------------------ */}

                <div className='flex flex-col justify-between h-full'>

                    <div className='flex flex-col p-4 gap-4'>

                        <div className='flex flex-wrap gap-2'>
                            {
                                requestDoc.map((request, index) => (
                                    <span key={index} className='bg-neutral-200 px-3 rounded-md flex flex-row gap-2 cursor-default whitespace-nowrap'>
                                        {`${request?.requestId?.toUpperCase()} ${request?.taskDescription}`}
                                        <img src={CloseIcon} alt='Delete' className='w-5 cursor-pointer' onClick={() => {

                                            setRequestDoc(requestDoc.filter(item => item.taskId !== request.taskId))

                                        }}></img>
                                    </span>
                                ))
                            }
                        </div>

                        <label className='font-semibold'> Change status:</label>

                        <input
                            className='Input-Field-Text'
                            value={
                                requestStatus === 'Completed' ?
                                    'Approve' : 'Reject'}
                            disabled={true}
                        ></input>

                        {/* Only shows when Reassign option is selected */}
                        {/* <div hidden={requestStatus !== 'Reassign'} className='flex flex-col gap-4'>
                                <label className='font-semibold'> Reassign request to: <span className='text-[#C4314B]'>*</span> </label>
                                <UserSearch
                                    selectedUser={selectedUser}
                                    setSelectedUser={setSelectedUser}
                                    errorMessage={''}
                                ></UserSearch>
                            </div> */}

                        <div className='flex flex-col gap-4'>
                            <label className='font-semibold'> Comment: {requestStatus !== 'Completed' && <span className='text-[#C4314B]'>*</span>} </label>
                            <textarea
                                className='Input-Field-TextArea'
                                placeholder={`Add a comment${requestStatus === 'Rejected' ? ' to reject this request...' : '...'}`}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                        </div>

                    </div>

                    {/* ------------------------------------------------------ */}
                    {/*  Buttons Container                                     */}
                    {/* ------------------------------------------------------ */}

                    <div>
                        <hr></hr>

                        <div className='flex flex-row my-3 gap-4 p-4' >

                            {/* Submit - Disabled if status is Reject and there is no comment. */}
                            <button
                                className={requestStatus === 'Rejected' ? 'Primary-Button-Red' : 'Primary-Button'}
                                disabled={requestStatus === 'Rejected' && comment.length === 0}
                                type='submit'
                                value='submit'
                                onClick={() => { handleSubmit() }}>
                                Save
                            </button>

                            {/* Cancel */}
                            <button
                                className={requestStatus === 'Rejected' ? 'Secondary-Button-Black' : 'Secondary-Button'}
                                onClick={() => {

                                    setRequestDoc([]);
                                    setComment('');
                                    setPaneStatus('pending');

                                }}>
                                Cancel
                            </button>

                        </div>

                    </div>

                </div>

            </dialog>
        </div>
    )
}
