// Libraries
import React, {useContext} from "react";
import { getAuth, signOut } from "firebase/auth";

// Contexts
import {SetAppStatus} from './GlobalContexts'

// Images
import SignOut from '../Components/Images/Icon_SignOut_White.svg'

/**
 * Trigger a Firebase signout on button click
 * 
 * @param {undefined} NotApplicable
 * 
 * ```
 * 
 * <Logout></Logout>    
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
 */

export default function Logout() {

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const setAppStatus = useContext(SetAppStatus)

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#next_steps
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  //  Logout handler
  //------------------------------------------------------

    function LogOutHandler(){

      signOut(auth)
      .then(() => {

        setAppStatus('signedout');

      })
      //An error happened.
      .catch(() => {

        setAppStatus('signedout');

      })
    }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className='flex flex-row gap-[5px] justify-between cursor-pointer py-[10px] px-[12px] border-[#333333] border-2 '>
      <label className='self-center mr-[10px] text-white cursor-pointer' onClick={() => LogOutHandler()}>Sign Out</label>
      <img className='w-[23px]' src={SignOut} alt='Sign Out'></img>
    </div>  
   )
    //------------------------------------------------------
}