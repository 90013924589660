//================================================================
//  Page: Account 
//================================================================

//  Purpose: Account Menu - Available only in Mobile view

//================================================================

//Libraries
import React from 'react';
import { Link } from 'react-router-dom';

export default function Account() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    // Account Menu - Add or remove pages here.
    <div className='p-4'>
      
      <h4 className='h-10'>Account</h4>

      <div className='flex flex-col gap-4'>

        {/* SUBSCRIPTIONS */}
        <div className='shadow p-3 rounded-md flex flex-col gap-2'>
          <label className='font-semibold'>Subscriptions</label>
          <p>View your subscriptions</p>
        </div>

        {/* REQUESTS */}
        <div className='shadow p-3 rounded-md flex flex-col gap-2'>
          <label className='font-semibold'>Requests</label>
          <p>View and manage your requests</p>
        </div>

        <Link className='no-underline text-black' to={'/approvals'}>
          {/* APPROVALS */}
          <div className='shadow p-3 rounded-md flex flex-col gap-2'>
            <label className='font-semibold'>Approvals</label>
            <p>Manage your approvals and delegate settings</p>
          </div>
        </Link>

      </div>

    </div>
  )
}