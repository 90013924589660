//================================================================
//  Page: Support 
//================================================================

//  Purpose: Support page for this application

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts

//Components

//Functions

//Images

//CSS
import './Support.css';


export default function Support() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='h-full flex flex-col gap-2 bg-gray-50 px-[3%] py-[2%]'>

      <h4 className='text-center'>
        Service Catalogue
      </h4>

      <div className='rounded-md p-4 w-[150px] h-[150px] bg-sky-100	cursor-pointer hover:bg-sky-200' onClick={() => navigate('/support/digitalbusinessimprovementform')}>
        Request New Software
      </div>

    </div>
    )
}