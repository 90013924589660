//================================================================
//  Page: Catalogue 
//================================================================

//  Purpose: This page displays the catalogue for all IT applications and hardware

//================================================================

//Libraries
import { useEffect, useState } from 'react';

//Components
import BreadCrumbs from '../../Components/Breadcrumbs/BreadCrumbs';
import PageComponent from '../../Components/PageComponent/PageComponent';
import AppCard from './Components/AppCard';

//Functions
import QueryListener from '../../Library/QueryListener';

//Images
import IconSearch from '../../Components/Images/Icon_Search_Grey.svg';
import ArrowIcon from '../../Components/Images/Icon_LeftArrow_Black.svg';

//CSS
import './Catalogue.css';


export default function Catalogue() {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Use to store the type of catalogue item
    const [itemType, setItemType] = useState('Application');

    // Search Input
    const [searchInput, setSearchInput] = useState('');

    // Toggle search input visibility
    const [showSearch, setShowSearch] = useState(false);

    // Holds the raw firestore data
    const [allCatalogueProducts, setAllCatalogueProducts] = useState([]);

    // Holds the current 
    const [filteredCatalogueProducts, setFilteredCatalogueProducts] = useState([]);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {
            setFilteredCatalogueProducts(allCatalogueProducts);
        };

        // Search Filter
        //  1. Application name
        const searchResults = allCatalogueProducts.filter((object) =>
            object?.application?.applicationname?.toLowerCase().includes(value.toLowerCase())
        );

        // Save the filtered search results to the useState
        setFilteredCatalogueProducts(searchResults);

    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onload
    //  1. Get all catalogueitems
    useEffect(() => {

        function onLoad(documents) {

            setAllCatalogueProducts([...documents]);
            setFilteredCatalogueProducts([...documents]);

        }

        function onChange(documents) {

            setAllCatalogueProducts([...documents]);
            setFilteredCatalogueProducts([...documents]);

        }

        function onError(error) {

            console.log('Error', error);

        }

        const unsubscribe = QueryListener('catalogueitems', [
            ['itemtype', '==', 'Application']
        ], onLoad, onChange, onError);

        return () => {
            unsubscribe();
        };

    }, []);


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='w-full md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl md:mx-auto px-0 md:px-4 py-4'>
            <PageComponent
                header={
                    <div className='flex flex-col gap-2 px-4 md:px-0'>
                        <div className='hidden md:block'>
                            <BreadCrumbs rootLinkName={'Store'} rootLinkRoute={'/'} childLinkName={'Catalogue'} />
                        </div>
                        <h4 className='flex items-center cursor-default mb-2'>
                            Catalogue
                        </h4>
                    </div>
                }
                body={
                    // When search is active, show search layout
                    showSearch && window.innerWidth < 768 ?
                        (
                            <div className='h-full flex flex-col'>

                                {/* Sticky Search Bar */}
                                <div className='flex flex-row items-center gap-3 pt-3 pb-2 border-b-[1px] border-b-[#D8D8D8] sticky top-0 bg-white z-10 px-4 md:px-0'>
                                    <img
                                        src={ArrowIcon}
                                        alt='Back'
                                        className='h-4'
                                        onClick={() => setShowSearch(false)}
                                    />
                                    <label htmlFor='searchInput'>
                                        <input
                                            id='searchInput'
                                            className='border-none p-0 m-0 outline-none'
                                            type='text'
                                            placeholder='Search'
                                            onChange={(e) => handleSearch(e.target.value)}
                                            value={searchInput}
                                        />
                                    </label>
                                </div>

                                {/* Scrollable Content */}
                                <div className='overflow-y-auto flex-grow px-4 md:px-0'>
                                    {
                                        filteredCatalogueProducts?.length === 0 ? (
                                            <label className='px-4'>No applications found.</label>
                                        ) : filteredCatalogueProducts.map((app, index) => (
                                            <AppCard
                                                key={index}
                                                index={index}
                                                app={app}
                                            ></AppCard>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className='flex flex-col gap-2 md:gap-6'>

                                {/* ----------------------------------- */}
                                {/*  Search & Filters                   */}
                                {/* ----------------------------------- */}

                                <div className='flex flex-row justify-between items-center gap-2 md:gap-3 pt-2 md:pt-3 pb-3 px-4 md:px-0'>

                                    {/* Filters */}
                                    <div className='flex flex-row gap-2 items-center md:gap-2'>

                                        {/* Applications */}
                                        <button className={itemType === 'Application' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setItemType('Application')}>
                                            Applications
                                        </button>

                                        {/* Hardware */}
                                        {/* <button className={itemType === 'Hardware' ? 'Filter-Primary' : 'Filter-Secondary'} disabled={true} onClick={() => setItemType('Hardware')}>
                                        Hardware
                                    </button> */}

                                    </div>

                                    {/* Search Bar (Desktop) */}
                                    <div className='hidden md:flex flex-row items-center gap-2'>

                                        <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[18px] border-1 border-solid border-[#D8D8D8] w-[350px]'>
                                            <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                            <label htmlFor='searchInput'>
                                                <input
                                                    id='searchInput'
                                                    className='border-none h-[36px] p-0 m-0 outline-none'
                                                    type='text'
                                                    placeholder='Search'
                                                    onChange={(e) => handleSearch(e.target.value)}
                                                    value={searchInput}
                                                ></input>
                                            </label>
                                        </div>

                                    </div>

                                    {/* Search Icon (Mobile) */}
                                    <div className='block md:hidden'>
                                        <div className='w-6 h-6' onClick={() => setShowSearch(true)}>
                                            <img className='w-6 h-6' src={IconSearch} alt='searchInput-icon' />
                                        </div>
                                    </div>

                                </div>

                                <hr className='my-0 border-b-[1px] border-b-[#D8D8D8] md:mx-6'/>

                                {/* Application Catalogue */}
                                {
                                    filteredCatalogueProducts.length === 0 ?
                                        (
                                            <label className='px-4 md:px-0'>
                                                No applications found.
                                            </label>
                                        )
                                        :
                                        (
                                            <div className='block md:flex flex-row flex-wrap gap-3 px-4 md:pt-4 md:px-0'>
                                                {
                                                    filteredCatalogueProducts.map((app, index) => (
                                                        <AppCard
                                                            key={index}
                                                            index={index}
                                                            app={app}
                                                        ></AppCard>
                                                    ))
                                                }
                                            </div>
                                        )
                                }
                            </div>
                        )
                }
            />

        </div>
    )
}