//================================================================
//  Component: AppCard
//================================================================

//  Purpose: A standardized component to display application cards with basic info and a navigational link to detailed catalogue view when clicked.

//  Properties:
//    - index = {A number, the index of the app card}
//    - app = {An object, the application object}

//  Example:
//    <AppCard
//      index={1}
//      app={applicationObject}
//    ></AppCard>   

//================================================================


// Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts

//Components

//Functions
// Images
import fallbackIcon from '../../../Components/Images/Icon_LLLogo_Green.svg';

//CSS

export default function AppCard({
  index,
  app,
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Function to navigate to the detailed catalogue view
  const goToCatalogueItem = (id) => {
    navigate(`/store/catalogue/${id}`);
  };

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    // Conditional rendering based on screen width (responsive layout)
    // Layout for larger screens (desktop view)
    <div
      key={index}
      className='flex flex-col border rounded-[14px] min-w-[216px] max-w-[216px] h-[226px] shadow-md cursor-pointer'
      onClick={() => goToCatalogueItem(app?.itemid)}
    >

      {/* Product Icon */}
      <div className='flex justify-center align-middle h-[156px]'>
        <img
          className='h-[128px] w-[128px] rounded my-[18px]'
          src={app?.iconurl === undefined ? fallbackIcon : app.iconurl}
          alt='product-icon'
        />
      </div>

      {/* App Info */}
      <div className='AppCard-Body-Container'>
        <h6 className='h-[20px] mb-0 whitespace-nowrap overflow-hidden text-ellipsis'>
          {app?.application?.applicationname} 
        </h6>
        <label>
          {app?.pricing?.amount} {app?.pricing?.currency} / {app?.pricing?.frequency} 
        </label>
      </div>

    </div>
  )
}