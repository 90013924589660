//================================================================
//  Component: Comments Thread
//================================================================

//  Purpose: This component handles the comment thread in the review and negotiate tab

//  Properties:
//  - task = {object, contains the commercial task details}

//  Example:
//    <CommentsThread
//      task={task}
//     ></CommentsThread>

//================================================================


//Libraries
import React, { useReducer, useContext, useState } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components

//Functions
import AddRemoveDocumentArray from '../../Library/AddRemoveDocumentArray';

//Images
import Loading from '../Images/Image_Loading_Ripple.svg';
import Send from '../Images/Icon_Send_White.svg';

//CSS


export default function CommentsThread({
    task
}) {
    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to determine the status of the comments thread > 'pending', 'onload'
    const [commentsStatus, setCommentsStatus] = useState('onload')

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store comments
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'newComment': '',
            'documents': [],
            'documentsErrorMessage': '',
            'formErrorMessage': ''
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function to post a new comment
    function postComment() {

        setCommentsStatus('pending');

        const commentId = `cm-${Date.now().toString()}${Math.floor(Math.random() * (9 - 10 + 1) + 10)}`;

        const commentObject = {
            'commentid': commentId,
            'message': formData?.newComment,
            'createddate': new Date(),
            'createdbyemail': getUser?.emailaddress,
            'createdbyname': `${getUser?.givenname} ${getUser?.surname}`,
        }

        return AddRemoveDocumentArray('tasks', task?.taskid, 'comments', commentObject, 'add')
            .then(() => {

                // Reset useReducer
                setFormData(
                    {
                        'newComment': '',
                        'documents': [],
                        'documentsErrorMessage': '',
                        'formErrorMessage': ''
                    }
                )

                setCommentsStatus('onload');

            }).catch((error) => {
                console.log(error)
                setFormData({ formErrorMessage: 'Oops! Something went wrong! Please try again!' })

            })



    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    //======================================================
    //  Pending
    //======================================================

    if (commentsStatus === 'pending') {
        return (
            <div className='flex justify-center items-center text-center'>
                <img alt='loading-circle-icon' src={Loading}></img>
            </div>
        )
    }

    //======================================================
    //  Onload
    //======================================================

    else {
        return (
            <div className='flex flex-col gap-2'>

                {/* Comments Thread */}
                {
                    task?.comments?.length > 0 &&
                    <div className='flex flex-col'>
                        {
                            task?.comments.map((comment, index) => (

                                <div key={index} className='flex flex-row gap-3 px-3 py-2 rounded-md'>

                                    {/* Commenter's Initials */}
                                    <label className='min-w-10 h-10 text-center uppercase bg-[#8ECDCA] p-1 rounded-full text-lg text-[#141C25] font-medium'>
                                        {comment.createdbyname.split(' ')[0][0].toUpperCase()}{comment.createdbyname.split(' ')[1][0].toUpperCase()}
                                    </label>

                                    <div className='bg-[#00A7A4] rounded-full min-w-2 h-2 my-3'></div>

                                    {/* User's Comment */}
                                    <div className='flex flex-col gap-1 w-full'>
                                        <label className='text-sm'>
                                            {comment?.createddate?.toDate()?.toLocaleString()}
                                        </label>
                                        <p className='font-semibold m-0'>{comment.createdbyname}</p>

                                        {comment.message}

                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }

                {/* Comments Field */}
                <div className='flex flex-row gap-3 px-3'>

                    {/* User's Initials */}
                    <label className='min-w-10 h-10 text-center uppercase bg-[#141C25] p-1 rounded-full text-lg text-[#8ECDCA] font-medium'>
                        {getUser?.givenname[0].toUpperCase()}{getUser?.surname[0].toUpperCase()}
                    </label>

                    {/* Text Field */}
                    <div className='FormComponent-Row'>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            placeholder='Add a comment...'
                            value={formData?.newComment}
                            onChange={(e) => setFormData({ 'newComment': e.target.value })}
                            disabled={task?.status === 'Completed'}
                        ></input>
                    </div>

                    {/* Buttons */}
                    <button className={`disabled:bg-neutral-400 bg-[#00A7A4] w-9 place-items-center rounded-md`} disabled={formData.newComment?.length === 0 || task?.status === 'Completed'} onClick={() => postComment()}>
                        <img src={Send} alt='Send' className='w-4'></img>
                    </button>

                </div>

                {/* Error Message */}
                <label className='font-medium text-[#DE0000] ml-[5px]' hidden={formData?.formErrorMessage.length === 0}>{formData?.formErrorMessage}</label>

            </div>
        )
    };

}
