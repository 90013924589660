//================================================================
//  Page: Request New Software 
//================================================================

//  Purpose: This form allows a user to request new software

//================================================================

//Libraries
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Contexts
import { GetUser, GetToast, SetToast } from '../../../../Library/GlobalContexts';

//Components
import UserSearch from '../../../../Components/UserSearchRHF/UserSearch';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import GenerateUniqueId from '../../../../Library/GenerateUniqueId';
import QueryDocument from '../../../../Library/QueryDocument';

//Images

//CSS
import './RequestNewSoftware.css';

export default function RequestNewSoftware() {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const getToast = useContext(GetToast);
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onChange
    //  1. If the type is success, hide after 10 seconds
    useEffect(() => {

        if (getToast?.type !== 'success') return;

        // 10 second delay
        setInterval(() => {

        setToast({
            'type': '',
            'message': '',
        });
    
        }, 60000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getToast.type]);

    // --------------------------------------------------
    //  Form State
    // --------------------------------------------------

    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Check if business request exists
    const validateBusinessRequest = (value) => {

        return QueryDocument('tasks', [['applicationname', '==', value]])
        .then((existingRequests) => {
            return existingRequests.length === 0 ? true : 'Found an existing application with this name.'

        })
        .catch(() => {
            return 'Failed to validate business request.'
    
        });

    }

    //  Submit Handler
    const onSubmit = (data) => {

        const docPromises = [];

        //=================================================
        //  Write Document to Firestore (Business Request)
        //=================================================

        const requestId = GenerateUniqueId('req', getUser?.emailaddress);
        const businessReqTaskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;

        const businessRequestTask = {
            'shortdescription': data.shortdescription,
            'businessowner': data.businessowner,
            'businesslead': data.businesslead,
            'linemanager': data.linemanager,
            'financemanager': data.financemanager,  
            'projectcode': data.projectcode,
            'tasknumber': data.tasknumber,
            'expendituretype': data.expendituretype,
            'budgeting': data.budgeting,
            'primarybeneficiary': data.primarybeneficiary,
            'secondarybeneficiary': data.secondarybeneficiary,         
            'commercialentity': data.commercialentity,
            'totalusers': data.totalusers,     
            'improvements': data.improvements,                           
            'summary': data.summary,   
            'revenuegrowth': data.revenuegrowth, 
            'arevenuegrowthcommence': data.arevenuegrowthcommence,  
            'fundsgrowth': data.fundsgrowth, 
            'afundsgrowthcommence': data.afundsgrowthcommence, 
            'loatype': data.loatype, 
            'anticipatedcost': data.anticipatedcost, 
            'comments': data.comments,
            'csmquestionnaire': {
                'applicationname': data.applicationname,
                'businessprocesses': data.shortdescription,
                'bufunction': data.primarybeneficiary,
                'businessowner': {
                    'email': data.businessowner,
                    'givenname': data.businessownergivenname,
                    'surname': data.businessownersurname
                },
                'projectcode': data.projectcode,
                'tasknumber': data.tasknumber,
                'expendituretype': data.expendituretype,
                'financemanager': data.financemanager,
                'totalusers': data.totalusers 
            },

            // Common Fields
            'taskid': businessReqTaskId,
            'requestid': requestId,
            'tasktype': 'Business Request',
            'taskname': 'New Software Request',
            'taskdescription': data.shortdescription,
            'applicationname': data.applicationname,
            'stage': '',
            'status': 'Not Started',
            'assignmentgroup': 'CSM',
            'requesttype': data.requesttype,
            'activityfeedvisible': true,
            'approvalhubvisible': false,
            'region': '',
            'assignedto': {
                'email': 'Unassigned',
                'givenname': '',
                'surname': ''
            },
            'assigneddate': '',
            'createddate': new Date(),
            'createdby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(), 
            'lastmodifiedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'activityfeed': [
                {
                    'activitydate': new Date(),
                    'actionedby': {
                        'email': getUser?.emailaddress,
                        'givenname': getUser?.givenname,
                        'surname': getUser?.surname
                    },
                    'action': 'created',
                    'comments': '',
                }
            ]
        };

        docPromises.push(
            WriteDocument('tasks', businessReqTaskId, businessRequestTask, true),
        )

        //=======================================================
        //  Write Document to Firestore (Line Manager Approval)
        //=======================================================

        const lineManagerTaskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;

        const lineManagerTask = {
            'taskid': lineManagerTaskId,
            'requestid': requestId,
            'tasktype': 'Line Manager Approval',
            'taskname': 'New Software Request',
            'taskdescription': `Line Manager Approval for ${data.applicationname}`,
            'applicationname': data.applicationname,
            'stage':'',
            'status': 'In Progress',
            'assignmentgroup': 'CSM',
            'requesttype': data.requesttype,
            'activityfeedvisible': true,
            'approvalhubvisible': true,
            'region': '',
            'assignedto': {
                'email': data.linemanager,
                'givenname': data.linemanagergivenname,
                'surname': data.linemanagersurname
            },
            'assigneddate': new Date(),
            'createddate': new Date(),
            'createdby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(), 
            'lastmodifiedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'activityfeed': [
                {
                    'activitydate': new Date(),
                    'actionedby': {
                        'email': data.linemanager,
                        'givenname': data.linemanagergivenname,
                        'surname': data.linemanagersurname
                    },
                    'action': 'assigned',
                    'comments': '',
                }
            ]
        }

        docPromises.push(
            WriteDocument('tasks', lineManagerTaskId, lineManagerTask, true),
        )

        //===========================================================
        //  Write Document to Firestore (Finance Manager Approval)
        //===========================================================

        const financeManagerTaskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;

        const financeManagerTask = {
            'taskid': financeManagerTaskId, 
            'requestid': requestId,
            'tasktype': 'Finance Manager Approval',
            'taskname': 'New Software Request',
            'taskdescription': `Finance Manager Approval for ${data.applicationname}`,
            'applicationname': data.applicationname,
            'stage': '',
            'status': 'In Progress',
            'assignmentgroup': 'CSM',
            'requesttype': data.requesttype,
            'activityfeedvisible': true,
            'approvalhubvisible': true,
            'region': '',
            'assignedto': {
                'email': data.financemanager,
                'givenname': data.financemanagergivenname,
                'surname': data.financemanagersurname
            },
            'assigneddate': new Date(),
            'createddate': new Date(),
            'createdby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(), 
            'lastmodifiedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'activityfeed': [
                {
                    'activitydate': new Date(),
                    'actionedby': {
                        'email': data.financemanager,
                        'givenname': data.financemanagergivenname,
                        'surname': data.financemanagersurname
                    },
                    'action': 'assigned',
                    'comments': '',
                }
            ]
        }

        docPromises.push(
            WriteDocument('tasks', financeManagerTaskId, financeManagerTask, true),
        )
        //===========================================================
        //  Write Document to Firestore (Instant Vendor Risk Report)
        //===========================================================

        const instantVendorRiskReportTaskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;

        const instantVendorRiskReportTask = {
            'taskid': instantVendorRiskReportTaskId,
            'requestid': requestId,
            'tasktype': 'Instant Vendor Risk Report',
            'taskname': 'New Software Request',
            'taskdescription': `Instant Vendor Risk Report for ${data.applicationname}`,
            'applicationname': data.applicationname,
            'stage': '',
            'status': 'Not Started',
            'assignmentgroup': 'CSM',
            'requesttype': data.requesttype,
            'activityfeedvisible': true,
            'approvalhubvisible': false,
            'assignedto': {
                'email': 'Unassigned',
                'givenname': '',
                'surname': ''
            },
            'assigneddate': '',
            'createddate': new Date(),
            'createdby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'lastmodifieddate': new Date(), 
            'lastmodifiedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
            },
            'activityfeed': [
                {
                    'activitydate': new Date(),
                    'actionedby': {
                        'email': '',
                        'givenname': 'Instant',
                        'surname': 'Report'
                    },
                    'action': 'created',
                    'comments': '',
                }
            ],
            'grade': '',
        }

        docPromises.push(
            WriteDocument('tasks', instantVendorRiskReportTaskId, instantVendorRiskReportTask, true),
        )

        //====================================
        //  Resolve Promises
        //====================================

        return Promise.all(docPromises)
        .then(() =>{

            // Set success on toast
            setToast({
                'type': 'success',
                'message': 'Digital Business Improvement Request submitted',
            });
            window.location.reload();

        })
        .catch((error) =>{
            console.log(error)

        })

    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col bg-[#F5F5F5]'>

            {/* Header */}
            <div className='Page-Header-Container'>
                <p className='font-medium text-[20px] mb-0'> Digital Business Improvement Request Form </p>

            </div>

            {/* Body */}
            <div className='Page-Section-Container m-[30px]'>
                <form className='BusinessRequest-Form' onSubmit={handleSubmit(onSubmit)}>

                    {/* =========================================================== */}
                    {/*  1. What is the name of this application? *                 */}
                    {/* =========================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 1. What is the name of this application? <span className='text-[#C4314B]'>*</span> </label>
                        <input
                            className={errors.applicationname ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                            {...register('applicationname', { 
                                required: 'This field is required',
                                validate: (value) => {
                                    return validateBusinessRequest(value);
                                }
                            })}
                            placeholder='Enter application name'
                            type='text'
                        ></input>
                                                                                                                  
                        {errors.applicationname && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.applicationname?.message}</p>}
                    </div>

                    {/* ========================================================================= */}
                    {/*  2. Please list business processes that this application is used for*.    */}
                    {/* ========================================================================= */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 2. Please list business processes that this application is used for. <span className='text-[#C4314B]'>*</span> </label>
                        <textarea
                            className={errors.shortdescription ? ('Input-Field-TextArea-Error'): ('Input-Field-TextArea')}
                            {...register('shortdescription', { 
                                required: 'This field is required'
                            })}
                            placeholder='Enter business processes'
                        ></textarea>

                        {errors.shortdescription && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.shortdescription?.message}</p>}
                    </div>

                    {/* =========================================================== */}
                    {/*  3. Who is the business owner of this application? *        */}
                    {/* =========================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 3. Who is the business owner of this application? <span className='text-[#C4314B]'>*</span> </label>
                        <div className='w-[450px]'>
                            <UserSearch
                                dataField={'businessowner'}
                                required={true}
                                register={register}
                                setValue={setValue}
                                errors={errors.businessowner}
                            ></UserSearch>    
                        </div>

                    </div>

                    {/* ============================================================================= */}
                    {/*  4. Who is the project lead / business lead supporting this application? *    */}
                    {/* ============================================================================= */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 4. Who is the project lead / business lead supporting this application? <span className='text-[#C4314B]'>*</span> </label>
                        <div className='w-[450px]'>
                            <UserSearch
                                dataField={'businesslead'}
                                required={true}
                                register={register}
                                setValue={setValue}
                                errors={errors.businesslead}
                            ></UserSearch>    
                        </div>

                    </div>

                    {/* ================================== */}
                    {/*  5. Who is your line manager? *    */}
                    {/* ================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 5. Who is your line manager? <span className='text-[#C4314B]'>*</span> </label>
                        <div className='w-[450px]'>
                            <UserSearch
                                dataField={'linemanager'}
                                required={true}
                                register={register}
                                setValue={setValue}
                                errors={errors.linemanager}
                            ></UserSearch>    
                        </div>
                        
                    </div>

                    {/* ====================================================== */}
                    {/*  6. Who is the finance manager for this request? *     */}
                    {/* ====================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'>6. Who is the finance manager for this request? <span className='text-[#C4314B]'>*</span> </label>
                        <div className='w-[450px]'>
                            <UserSearch
                                dataField={'financemanager'}
                                required={true}
                                register={register}
                                setValue={setValue}
                                errors={errors.financemanager}
                            ></UserSearch>    
                        </div>
                        
                    </div>

                    {/* ============================ */}
                    {/*  7. Project Code *           */}
                    {/* ============================ */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 7.  Project Code <span className='text-[#C4314B]'>*</span> </label>
                        <input
                            className={errors.projectcode ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                            style={{ width: '300px' }}
                            {...register('projectcode', { 
                                required: 'This field is required',
                                pattern: {
                                    value: /^\d{6}$/,
                                    message: 'This field must contain exactly 6 digits',
                                },                            
                            })}
                            placeholder='Enter a project code'
                            type='text'
                        ></input>
                                                                                                                  
                        {errors.projectcode && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.projectcode?.message}</p>}
                    </div>

                    {/* ============================ */}
                    {/*  8. Task Number *            */}
                    {/* ============================ */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 8. Task Number <span className='text-[#C4314B]'>*</span> </label>
                        <input
                            className={errors.tasknumber ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                            style={{ width: '300px' }}
                            {...register('tasknumber', { 
                                required: 'This field is required'                         
                            })}
                            placeholder='Enter a task number'
                            type='text'
                        ></input>
                                                                                                                  
                        {errors.tasknumber && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.tasknumber?.message}</p>}
                    </div>

                    {/* ================================= */}
                    {/*  9. Expenditure Type *            */}
                    {/* ================================= */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 9. Expenditure Type <span className='text-[#C4314B]'>*</span> </label>
                        <input
                            className={errors.expendituretype ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                            style={{ width: '300px' }}
                            {...register('expendituretype', { 
                                required: 'This field is required'                        
                            })}
                            placeholder='Enter expenditure type'
                            type='text'
                        ></input>
                                                                                                                  
                        {errors.expendituretype && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.expendituretype?.message}</p>}
                    </div>

                    {/* ========================================== */}
                    {/*  10. Who is budgeting for this? *          */}
                    {/* ========================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 10. Who is budgeting for this? <span className='text-[#C4314B]'>*</span> </label>
                        <select
                            className={errors.budgeting ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('budgeting', { 
                                required: 'This field is required'
                            })}
                            style={{ width: '300px'}}
                        >
                            <option hidden value=''>-</option>
                            <option value='Business Budgeted'> Business Budgeted </option>
                            <option value='IT Budgeted'> IT Budgeted </option>
                        </select>
                        {errors.budgeting && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.budgeting?.message}</p>}
                    </div>

                    {/* ========================================================================================== */}
                    {/*  11.  Who will be the primary beneficiary of this business improvement solution? *         */}
                    {/* ========================================================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 11. Who will be the primary beneficiary of this business improvement solution? <span className='text-[#C4314B]'>*</span> </label>
                        <select
                            className={errors.primarybeneficiary ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('primarybeneficiary', { 
                                required: 'This field is required'
                            })}
                            style={{ width: '300px'}}
                        >
                            <option hidden value=''>-</option>
                            <option value='Investment Management'> Investment Management </option>
                            <option value='Development'> Development </option>
                            <option value='Construction'> Construction </option>           
                            <option value='Functions'> Functions </option>             
                            <option value='Digital'> Digital </option>             

                        </select>
                        {errors.primarybeneficiary && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.primarybeneficiary?.message}</p>}
                    </div>

                    {/* ========================================================================================== */}
                    {/*  12. Who will be the secondary beneficiary(s) for this business improvement solution? *    */}
                    {/* ========================================================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 12. Who will be the secondary beneficiary(s) for this business improvement solution? <span className='text-[#C4314B]'>*</span> </label>            
                        <p className='text-sm text-slate-600 mb-1 p-0'>To select more than one, press Ctrl and select your options.</p>     
                        <select
                            className={errors.secondarybeneficiary ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('secondarybeneficiary', { 
                                required: 'This field is required',
                                validate: (value) => {
                                        if(value.includes(watch('primarybeneficiary'))) {
                                            return 'This is the primary beneficiary, please select another option.'
                                        }
                                }
                            })}
                            style={{ width: '400px', height: '100px' }}
                            multiple
                        >
                            <option hidden value=''>-</option>
                            <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                            <option value='EHS'> EHS </option>
                            <option value='Finance'> Finance </option>
                            <option value='Group Investor Relations'> Group Investor Relations </option>
                            <option value='IT'> IT </option>
                            <option value='Legal'> Legal </option>
                            <option value='People & Culture'> People & Culture </option>
                            <option value='Risk and Insurance'> Risk and Insurance </option>
                            <option value='Supply Chain'> Supply Chain </option>
                            <option value='Sustainability'> Sustainability </option>
                            <option value='Tax'> Tax </option>
                            <option value='Treasury'> Treasury </option>

                        </select>
                        {errors.secondarybeneficiary && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.secondarybeneficiary?.message}</p>}
                    </div>

                    {/* ================================================================== */}
                    {/*  13. Commercial Entity to sign application agreement (if known) *  */}
                    {/* ================================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 13. Commercial Entity to sign application agreement (if known) <span className='text-[#C4314B]'>*</span> </label>
                        <input
                            className={errors.commercialentity ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                            style={{ width: '300px' }}
                            {...register('commercialentity', { 
                                required: 'This field is required'                        
                            })}
                            placeholder='Enter commercial entity'
                            type='text'
                        ></input>
                                                                                                                  
                        {errors.commercialentity && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.commercialentity?.message}</p>}
                    </div>

                    {/* ======================================= */}
                    {/*  14. Approximate number of users *      */}
                    {/* ======================================= */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 14. Approximate number of users <span className='text-[#C4314B]'>*</span> </label>
                        <input
                            className={errors.totalusers ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                            style={{ width: '300px' }}
                            {...register('totalusers', { 
                                required: 'This field is required',
                                min: { value: 0, message: 'Number of users must be a positive number' }                        
                            })}
                            placeholder='Enter total users'
                            type='number'
                        ></input>
                                                                                                                  
                        {errors.totalusers && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.totalusers?.message}</p>}
                    </div>

                    {/* ========================================== */}
                    {/*  15. What type of request is this? *       */}
                    {/* ========================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 15. What type of request is this? <span className='text-[#C4314B]'>*</span> </label>
                        <select
                            className={errors.requesttype ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('requesttype', { 
                                required: 'This field is required'
                            })}
                            style={{ width: '300px'}}
                        >
                            <option hidden value=''>-</option>
                            <option value='New Application'> New Application </option>
                            <option value='Renewal'> Renewal </option>
                        </select>
                        {errors.requesttype && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.requesttype?.message}</p>}
                    </div>

                    {/* ======================================================================== */}
                    {/*  16. Will this application improve one or more of the following? *       */}
                    {/* ======================================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 16. Will this application improve one or more of the following? <span className='text-[#C4314B]'>*</span> </label>            
                        <p className='text-sm text-slate-600 mb-1 p-0'>To select more than one, press Ctrl and select your options.</p>         
                        <select
                            className={errors.improvements ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('improvements', { 
                                required: 'This field is required'
                            })}
                            style={{ width: '400px', height: '100px' }}
                            multiple
                        >
                            <option hidden value=''>-</option>
                            <option value='Business efficiency'> Business efficiency </option>
                            <option value='Customer / Client Satisfaction'> Customer / Client Satisfaction </option>
                            <option value='Enhancement of existing business critical systems'> Enhancement of existing business critical systems </option>
                            <option value='ESG requirements or credentials'> ESG requirements or credentials </option>
                            <option value='Investment in our people'> Investment in our people </option>
                            <option value='Overcome risks associated with shadow IT'> Overcome risks associated with shadow IT </option>
                            <option value='Regulatory compliance'> Regulatory compliance </option>
                            <option value='Risk mitigation'> Risk mitigation </option>
                        </select>
                        {errors.improvements && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.improvements?.message}</p>}
                    </div>

                    {/* =================================================================================================== */}
                    {/*  17. Provide a summary of how this initiative will improve the items chosen above *                 */}
                    {/* =================================================================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'>17. Provide a summary of how this initiative will improve the items chosen above <span className='text-[#C4314B]'>*</span> </label>
                        <textarea
                            className={errors.summary ? ('Input-Field-TextArea-Error'): ('Input-Field-TextArea')}
                            {...register('summary', { 
                                required: 'This field is required',
                                validate: (value) => {
                                        if(value.split(' ').length > 100) {
                                            return 'Please enter a summary in 100 words or less.'
                                        }
                                }
                            })}
                            placeholder='Enter a summary in 100 words or less.'
                        ></textarea>
                        {errors.summary && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.summary?.message}</p>}
                    </div>

                    {/* =========================================================== */}
                    {/*  18. Does this initiative drive revenue growth? *           */}
                    {/* =========================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'>18. Does this initiative drive revenue growth? <span className='text-[#C4314B]'>*</span> </label>
                        <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                            <label className='Radio-Buttons-Container'>
                                Yes
                                <input 
                                    type='radio' 
                                    value='yes'
                                    {...register('revenuegrowth', {
                                        required: 'This field is required.'
                                    })}
                                ></input>
                                <span className={errors.revenuegrowth ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                            </label>

                            <label className='Radio-Buttons-Container'>
                                No
                                <input 
                                    type='radio' 
                                    value='no'
                                    {...register('revenuegrowth', {
                                        required: 'This field is required.'
                                    })}
                                ></input>
                                <span className={errors.revenuegrowth ? 'Radio-Checkmark-Error'  : 'Radio-Checkmark'}></span>
                            </label>
                        </div>
                        {errors.revenuegrowth && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.revenuegrowth?.message}</p>}
                    </div>

                    {/* =========================================================== */}
                    {/*  18a. If yes, when would revenue growth commence?*          */}
                    {/* =========================================================== */}

                    <div className='BusinessRequest-Form-Row' hidden={watch('revenuegrowth') !== 'yes'}>
                        <label className='font-medium'> 18a. If yes, when would revenue growth commence? <span className='text-[#C4314B]'>*</span> </label>
                        <select
                            className={errors.arevenuegrowthcommence ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('arevenuegrowthcommence', {
                                required: watch('revenuegrowth') === 'yes' ? 'This field is required' : false
                            })}
                            style={{ width: '450px' }}
                        >
                            <option hidden value=''>-</option>
                            <option value='Within 6 months'> Within 6 months </option>
                            <option value='6 months to 1 year'> 6 months to 1 year </option>
                            <option value='1 to 2 years'> 1 to 2 years </option>
                            <option value='In more than 2 years'> In more than 2 years </option>
                        </select>
                        {errors.arevenuegrowthcommence && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.arevenuegrowthcommence?.message}</p>}
                    </div>

                    {/* =========================================================== */}
                    {/*  19. Does this product accelerate funds growth? *           */}
                    {/* =========================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> 19. Does this product accelerate funds growth? *<span className='text-[#C4314B]'>*</span> </label>
                        <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                            <label className='Radio-Buttons-Container'>
                                Yes
                                <input 
                                    type='radio' 
                                    value='yes'
                                    {...register('fundsgrowth', {
                                        required: 'This field is required.'
                                    })}
                                ></input>
                                <span className={errors.fundsgrowth ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                            </label>

                            <label className='Radio-Buttons-Container'>
                                No
                                <input 
                                    type='radio' 
                                    value='no'
                                    {...register('fundsgrowth', {
                                        required: 'This field is required.'
                                    })}
                                ></input>
                                <span className={errors.fundsgrowth ? 'Radio-Checkmark-Error'  : 'Radio-Checkmark'}></span>
                            </label>
                        </div>
                        {errors.fundsgrowth && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.fundsgrowth?.message}</p>}
                    </div>

                    {/* =========================================================== */}
                    {/*  19a. If yes, when would funds growth commence? *           */}
                    {/* =========================================================== */}

                    <div className='BusinessRequest-Form-Row' hidden={watch('fundsgrowth') !== 'yes'}>
                        <label className='font-medium'>19a. If yes, when would funds growth commence? <span className='text-[#C4314B]'>*</span> </label>
                        <select
                            className={errors.afundsgrowthcommence ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('afundsgrowthcommence', {
                                required: watch('fundsgrowth') === 'yes' ? true : false
                            })}
                            style={{ width: '450px' }}
                        >
                        <option hidden value=''>-</option>
                        <option value='Within 6 months'> Within 6 months </option>
                        <option value='6 months to 1 year'> 6 months to 1 year </option>
                        <option value='1 to 2 years'> 1 to 2 years </option>
                        <option value='In more than 2 years'> In more than 2 years </option>
                        </select>
                        {errors.afundsgrowthcommence && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.afundsgrowthcommence?.message}</p>}
                    </div>

                    {/* =========================================================== */}
                    {/*  20. What LoA Type is this initiative / application? *      */}
                    {/* =========================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'>20. What LoA Type is this initiative / application? <span className='text-[#C4314B]'>*</span> </label>
                        <select
                            className={errors.loatype ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('loatype', {
                                required: 'This field is required.'
                            })}
                        >
                        <option hidden value=''>-</option>
                        <option value='IT Capex'> IT Capex </option>
                        <option value='IT Opex'> IT Opex </option>
                        <option value='Project Based'> Project Based </option>
                        </select>
                        {errors.loatype && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.loatype?.message}</p>}
                    </div>

                    {/* ================================================================ */}
                    {/*  21. What is the anticipated complete cost of this initiative? * */}
                    {/* ================================================================ */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'>21. What is the anticipated complete cost of this initiative? <span className='text-[#C4314B]'>*</span> </label>
                        <select
                            className={errors.anticipatedcost ? ('Input-Field-Select-Error'): ('Input-Field-Select')}
                            {...register('anticipatedcost', {
                                required: 'This field is required.'
                            })}
                        >
                        <option hidden value=''>-</option>
                        <option value='$0'> $0 </option>
                        <option value='$1 to $10k'> $1 to $10k </option>
                        <option value='$10k to $30k'> $10k to $30k </option>
                        <option value='$30k to $50k'> $30k to $50k </option>
                        <option value='$50k to $100k'> $50k to $100k </option>
                        <option value='$100k to $250k'> $100k to $250k </option>
                        <option value='$250k or more'> $250k or more </option>
                        </select>
                        {errors.anticipatedcost && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.anticipatedcost?.message}</p>}
                    </div>

                    {/* =================================================== */}
                    {/*  Comments                                           */}
                    {/* =================================================== */}

                    <div className='BusinessRequest-Form-Row'>
                        <label className='font-medium'> Comments </label>
                        <textarea
                            className={errors.comments ? ('Input-Field-TextArea-Error'): ('Input-Field-TextArea')}
                            {...register('comments')}
                            placeholder='Enter a comment'
                        ></textarea>
                    </div>                                                                                                                       

                    {/* ===================================== */}
                    {/*  Submit Buttons                       */}
                    {/* ===================================== */}

                    <div>
                        <button style={{marginTop: '30px'}} className='Primary-Button' type='submit' value='submit'>Submit</button>
                    </div>

                </form>
            </div>

        </div>
    )

}