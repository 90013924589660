//================================================================
//  Page: ApprovalDetails
//================================================================

//  Purpose: Detailed Approval page - shows individual approval in detail

//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import SideNav from '../../../Components/SideNav/SideNav';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadCrumbs';
import TabView from '../../../Components/TabView/TabView';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
// import UserSearch from '../../../Components/UserSearch/UserSearch';
import HistoryTimeline from '../../../Components/HistoryTimeline/HistoryTimeline';
import CommentsThread from '../../../Components/CommentsThread/CommentsThread';

//Functions
import DocumentListener from '../../../Library/DocumentListener';
import ConvertDate from '../../../Library/ConvertDate';
import WriteDocument from '../../../Library/WriteDocument';

//Images
import FileIcon from '../../../Components/Images/Icon_File_Black.svg';
// import LinkIcon from '../../../Components/Images/Icon_OpenNewWindow_Blue.svg';

//CSS
import '../Account.css';

export default function ApprovalDetails() {

    //------------------------------------------------------
    //  useContexts & React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const params = useParams();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to determine status of the page > 'pending', 'onload', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Contains Firestore Document of the request
    const [requestDoc, setRequestDoc] = useState(undefined);

    // For changing request status dropdown > 'Approve', 'Reassign', 'Reject'
    const [requestStatus, setRequestStatus] = useState('');

    // Some status changes require an additional comment
    const [changeStatusComment, setChangeStatusComment] = useState('');

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // // Used to store the search data
    // const [selectedUser, setSelectedUser] = useReducer(
    //     (state, newState) => ({ ...state, ...newState }),
    //     {
    //         'newUserInput': '', // email of the selected user
    //         'newUserName': '', // fullname of the selected user
    //         'newGivenname': '', // givenname of the selected user
    //         'newSurname': '', // surname of the selected user
    //         'searchResults': '',
    //         'queryMessage': '',
    //     }
    // );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Disables the "Proceed" button if conditions aren't met
    function isSubmitDisabled() {

        if (getUser.emailaddress !== requestDoc?.assignedto.email) return true;

        // Enable button when dropdown option is selected
        if (requestStatus === 'Approve') return false;

        // if (requestStatus === 'Reassign') {
        //     return selectedUser?.newUserInput && changeStatusComment.length > 0 ? false : true;
        // }

        if (requestStatus === 'Reject') {
            return changeStatusComment.length > 0 ? false : true;
        }

        return true;

    }

    // Submits form
    function handleSubmit() {

        if (requestStatus === 'Approve') {

            const newActivityFeed = [...requestDoc.activityfeed, {
                'action': 'approved',
                'actionedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'activitydate': new Date(),
                'comments': changeStatusComment
            }]

            const docObject = {
                'status': 'Completed',
                'activityfeed': newActivityFeed,
                'lastmodifiedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'lastmodifieddate': new Date(),
            }

            return WriteDocument('tasks', requestDoc?.taskid, docObject, true)
                .then(() => {

                    setRequestStatus('');
                    setChangeStatusComment('');

                })
                .catch((err) => {

                    console.log(err);
                    setPageStatus('error-fatal');

                })
        }

        // if (requestStatus === 'Reassign') {

        //     const newActivityFeed = [...requestDoc.activityfeed, {
        //         'action': 'reassigned',
        //         'actionedby': {
        //             'email': getUser.emailaddress,
        //             'givenname': getUser.givenname,
        //             'surname': getUser.surname,
        //         },
        //         'activitydate': new Date(),
        //         'comments': changeStatusComment
        //     }]

        //     const docObject = {
        //         'activityfeed': newActivityFeed,
        //         'assignedto': {
        //             'email': selectedUser?.newUserInput,
        //             'givenname': selectedUser?.newGivenname,
        //             'surname': selectedUser?.newSurname,
        //         },
        //         'lastmodifiedby': {
        //             'email': getUser.emailaddress,
        //             'givenname': getUser.givenname,
        //             'surname': getUser.surname,                
        //         },
        //         'lastmodifieddate': new Date(),
        //     }

        //     return WriteDocument('tasks', requestDoc?.taskid, docObject, true)
        //         .then(() => {

        //             setRequestStatus('');
        //             setChangeStatusComment('');

        //         })
        //         .catch((err) => {

        //             console.log(err);
        //             setPageStatus('error-fatal');

        //         })
        // }

        if (requestStatus === 'Reject') {

            const newActivityFeed = [...requestDoc.activityfeed, {
                'action': 'rejected',
                'actionedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'activitydate': new Date(),
                'comments': changeStatusComment
            }]

            const docObject = {
                'status': 'Rejected',
                'activityfeed': newActivityFeed,
                'lastmodifiedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'lastmodifieddate': new Date(),
            }

            return WriteDocument('tasks', requestDoc?.taskid, docObject, true)
                .then(() => {

                    setRequestStatus('');
                    setChangeStatusComment('');

                })
                .catch((err) => {

                    console.log(err);
                    setPageStatus('error-fatal');

                })
        }

    }


    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get task document from Firestore
    useEffect(() => {

        const taskid = params.id;

        function onLoadChange(document) {

            setRequestDoc(document);

            setPageStatus('onload');

        }

        function onError(error) {

            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = DocumentListener('tasks', taskid, onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        }

        // eslint-disable-next-line
    }, [pageStatus]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='Account-Container'>

            <div className='Account-Body'>
                <PageComponent
                    status={pageStatus}
                    header={
                        <div className='flex flex-col p-4 bg-neutral-100 h-32 w-full border-solid border-1 border-neutral-300'>
                            <BreadCrumbs rootLinkName={'My Approvals'} rootLinkRoute={'/approvals'} childLinkName={requestDoc?.requestid?.toUpperCase()} />
                            <h4 className='flex items-center h-full cursor-default'>{requestDoc?.taskname}</h4>
                        </div>}
                    body={
                        <div className={`Request-Layout ${
                            requestDoc?.status === 'Completed' ||  requestDoc?.status === 'Rejected' ? 'Request-actioned' : ''
                          }`}>

                            {/* MAIN PAGE CONTENT-REQUEST INFORMATION */}
                            <div className='Request-Summary'>

                                <div className='flex flex-col gap-3 p-8'>

                                    <div className='flex flex-col gap-2'>
                                        <label className='font-semibold flex items-center'>Name</label>
                                        <p className='flex items-center'>{requestDoc?.taskname}</p>
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label className='font-semibold flex items-center'>Short Description</label>
                                        <p className='flex items-center'>{requestDoc?.taskdescription}</p>
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label className='font-semibold flex items-center'>Assigned To</label>
                                        <div className='flex flex-col pb-2'>
                                            <p className='m-0 flex items-center'>{requestDoc?.assignedto.givenname} {requestDoc?.assignedto.surname}</p>
                                            <p className='m-0 flex items-center text-sm text-[#424242]'>{requestDoc?.assignedto.email}</p>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-3'>
                                        <label className='font-semibold flex items-center'>Status</label>
                                        <StatusLabel status={requestDoc?.status} />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label className='font-semibold flex items-center'>Created Date</label>
                                        <p className='flex items-center'>{ConvertDate(requestDoc?.createddate)}</p>
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label className='font-semibold flex items-center'>Uploaded Documents</label>
                                        <div className='flex flex-col py-2 border-1 rounded-md'>
                                            {requestDoc?.supportingdocs && requestDoc.supportingdocs.length > 0 ?
                                                requestDoc.supportingdocs.map((doc, index) => (
                                                    <div key={index} className='flex flex-row'>
                                                        <img className='h-6 my-auto mx-2' alt='File' src={FileIcon} />
                                                        <a className='flex items-center cursor-pointer' target='_blank' rel='noopener noreferrer' href={doc.fileUrl}>{doc.fileName}</a>
                                                    </div>
                                                ))
                                                :
                                                <p className='my-auto mx-3'>N/A</p>
                                            }
                                        </div>
                                    </div>

                                    {/* TODO - View Original Request Link */}
                                    {/* <div className=' flex flex-row gap-2 h-24 items-center'>
                                        <a href=''>View original request</a>
                                        <img src={LinkIcon} alt='Open in new tab' className='h-4'></img>
                                    </div> */}

                                </div>

                                <hr className='text-neutral-500 p-0 m-0'></hr>

                                {/* REQUEST HISTORY & NOTES */}
                                <TabView
                                    defaultView={1}
                                    oneTitle={'History'}
                                    oneContent={
                                        requestDoc?.activityfeed?.toReversed().map((item, index) => (
                                            <HistoryTimeline
                                                item={item}
                                                key={index}
                                            ></HistoryTimeline>
                                        ))

                                    }
                                    twoTitle={'Notes'}
                                    twoTag={requestDoc?.comments?.length}
                                    twoContent={
                                        <div className='px-4'>
                                            <CommentsThread task={requestDoc}></CommentsThread>
                                        </div>
                                    }
                                ></TabView>

                            </div>

                            {/* CHANGE REQUEST STATUS */}
                            <div className='Manage-Request flex flex-col justify-between overflow-hidden h-[calc(100vh-116px)]'>

                                <div>
                                    <label className='p-4 font-semibold text-lg border-b-neutral-300 border-b w-full'>Approval Status</label>

                                    <div className='flex flex-col p-4 gap-4'>

                                        <label className='font-semibold'> Change status:</label>

                                        <select
                                            className='Input-Field-Select'
                                            value={requestStatus}
                                            disabled={requestDoc?.status !== 'In Progress'}
                                            onChange={(e) => setRequestStatus(e.target.value)}
                                        >
                                            {/* If completed, show as "Approved", otherwise show "Rejected". If pending approval, show "Select" */}
                                            <option hidden value=''>{requestDoc?.status !== 'In Progress' ? requestDoc?.status === 'Completed' ? 'Approved' : requestDoc?.status : 'Select'}</option>
                                            <option value='Approve'>Approve</option>
                                            {/* <option value='Reassign'>Reassign</option> */}
                                            <option className='text-[#DE0000]' value='Reject'>Reject</option>
                                        </select>

                                        {/* Only shows when Reassign option is selected */}
                                        {/* <div hidden={requestStatus !== 'Reassign'} className='flex flex-col gap-4'>
                                            <label className='font-semibold'> Reassign request to: <span className='text-[#C4314B]'>*</span> </label>
                                            <UserSearch
                                                selectedUser={selectedUser}
                                                setSelectedUser={setSelectedUser}
                                                errorMessage={''}
                                            ></UserSearch>
                                        </div> */}

                                        {/* Only shows when an option is selected */}
                                        <div hidden={requestStatus.length === 0} className='flex flex-col gap-4'>
                                            <label className='font-semibold'> Comment: {requestStatus !== 'Approve' && <span className='text-[#C4314B]'>*</span>} </label>
                                            <textarea
                                                className='Input-Field-TextArea'
                                                placeholder={`Add a comment${requestStatus === 'Reassign' ? ' for the new approver...' : '...'}`}
                                                value={changeStatusComment}
                                                onChange={(e) => setChangeStatusComment(e.target.value)}
                                            ></textarea>
                                        </div>

                                    </div>
                                </div>

                                {/* TODO - fix sticky portion */}
                                <div className='sticky self-start top-0 border-t-neutral-300 border-t w-full py-3 px-4 flex flex-row gap-3'>

                                    <button className='Primary-Button' disabled={isSubmitDisabled()} onClick={() => handleSubmit()}>
                                        Proceed
                                    </button>

                                    <button className='Secondary-Button' onClick={() => {
                                        setRequestStatus('');
                                        setChangeStatusComment('');
                                    }}>
                                        Cancel
                                    </button>

                                </div>

                            </div>
                        </div>}
                ></PageComponent>
            </div>

            <div className='Account-SideNav'>
                <SideNav></SideNav>
            </div>

        </div>
    )
}