//================================================================
//  Page: Home 
//================================================================

//  Purpose: Home page for this application

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import IconSearch from '../../Components/Images/Icon_Search_Grey.svg';

//Functions
import QueryListener from '../../Library/QueryListener';

//Images
import ArrowIcon from '../../Components/Images/Icon_LeftArrow_Black.svg';

//CSS
import './Home.css';
import AppCard from './Components/AppCard';


export default function Home() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Holds the current search status
  const [isSearching, setIsSearching] = useState(false);

  // Recommended apps
  const [recommendedApps, setRecommendedApps] = useState([]);


  // Left scroll
  const [canScrollLeft, setCanScrollLeft] = useState(false);

  // Right scroll
  const [canScrollRight, setCanScrollRight] = useState(false);

  //------------------------------------------------------
  // useRef
  //------------------------------------------------------

  // Recommended apps container
  const recommendedAppRef = useRef(null);


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  // Function to scroll the container to the right
  const scrollRight = () => {

    if (recommendedAppRef.current) {

      recommendedAppRef.current.scrollBy({ left: 200, behavior: 'smooth' });

      // Check after scroll if it's possible to scroll right
      setCanScrollLeft(recommendedAppRef.current.scrollLeft > 0);

      setCanScrollRight(
        recommendedAppRef.current.scrollLeft < recommendedAppRef.current.scrollWidth - recommendedAppRef.current.clientWidth
      );
    }
  };

  // Function to scroll the container to the left
  const scrollLeft = () => {

    if (recommendedAppRef.current) {

      recommendedAppRef.current.scrollBy({ left: -200, behavior: 'smooth' });

      // Check after scroll if it's possible to scroll left
      setCanScrollLeft(recommendedAppRef.current.scrollLeft > 0);

      setCanScrollRight(
        recommendedAppRef.current.scrollLeft < recommendedAppRef.current.scrollWidth - recommendedAppRef.current.clientWidth
      );
    }
  };

  // Handling scroll manually (for updating the scroll state on scroll)
  const handleScroll = () => {

    if (recommendedAppRef.current) {

      setCanScrollLeft(recommendedAppRef.current.scrollLeft > 0);

      setCanScrollRight(recommendedAppRef.current.scrollLeft < recommendedAppRef.current.scrollWidth - recommendedAppRef.current.clientWidth);
    }
  };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // onload
  //  1. Get all recommended apps
  useEffect(() => {

    function onLoad(documents) {

      const filteredDocuments = documents.filter(doc =>
        doc?.tags?.businessunit?.some(category => getUser?.businessunit?.includes(category))
      );

      setRecommendedApps([...filteredDocuments]);

    }

    function onChange(documents) {

      const filteredDocuments = documents.filter(doc =>
        doc?.tags?.businessunit?.some(category => getUser?.businessunit?.includes(category))
      );

      setRecommendedApps([...filteredDocuments]);

    }

    function onError(error) {

      console.log('Error', error);

    }

    const unsubscribe = QueryListener('catalogueitems', [
      ['itemtype', '==', 'Application'],
      ['tags.region', 'array-contains', getUser?.country],
    ], onLoad, onChange, onError);

    return () => {
      unsubscribe();
    };

  }, [getUser]);


  // Check initial scroll state
  useEffect(() => {

    if (recommendedAppRef.current) {

      setCanScrollRight(recommendedAppRef.current.scrollWidth > recommendedAppRef.current.clientWidth);
    }

  }, [recommendedApps]);



  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    window.innerWidth <= 768 && isSearching ?
      <div className='h-full flex-col flex md:hidden '>

        {/* Sticky Search Bar */}
        <div className='flex flex-row items-center gap-3 pt-2 px-4 pb-2 border-b-[1px] border-b-[#D8D8D8] sticky top-0 bg-white z-10'>
          <img
            src={ArrowIcon}
            alt='Back'
            className='h-5'
            onClick={() => setIsSearching(false)}
          />
          <label htmlFor='searchInput'>
            <input
              id='searchInput'
              className='border-none p-0 m-0 outline-none'
              type='text'
              placeholder='Search for applications'
            />
          </label>
        </div>

        {/* Scrollable Content */}
        <div className='px-4 overflow-y-auto flex-grow'>

        </div>
      </div>
      :

      <div className='container flex flex-col gap-2  py-[1%]'>

        {/* ========================= */}
        {/*          Header           */}
        {/* ========================= */}

        {/* ----------------------------------- */}
        {/*  Search & Filters                   */}
        {/* ----------------------------------- */}

        <div className='flex flex-row justify-between items-center gap-3 py-3  border-b-[1px] border-b-[#D8D8D8]'>

          <div className='flex flex-row items-center gap-2'>

            {/* Applications */}
            <button className={'Filter-Primary'}>
              Applications
            </button>

            {/* Hardware */}
            <button className={'Filter-Secondary'} disabled={true}>
              Hardware
            </button>

          </div>

          {/* Search Bar */}
          <div className='flex items-center gap-2'>

            {/* Show search bar on medium screens and above */}
            <div className='hidden md:grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[18px] border border-solid border-[#D8D8D8] w-[350px]'>
              <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon' />
              <label htmlFor='searchInput'>
                <input id='searchInput' className='border-none h-[36px] p-0 m-0 outline-none' type='text' placeholder='Search'></input>
              </label>
            </div>

            {/* Show only search icon on mobile */}
            <button className='md:hidden p-2' onClick={() => setIsSearching(true)}>
              <img src={IconSearch} alt='search-icon' height={25} width={25}></img>
            </button>
          </div>
        </div>

        <div className='flex justify-center'>
          <h4 className='font-bold text-center mt-3'>
            Hello, {getUser.givenname}!
            <div className='font-light mt-[5px] text-lg'>Welcome to the IT Store</div>
          </h4>
        </div>

        {/* ========================= */}
        {/*     Apps Container        */}
        {/* ========================= */}

        <div className='flex flex-col items-center mt-[20px]'>
          <div className='flex flex-col md:flex-row gap-4 justify-center'>

            {/* Get Support Card */}
            <div className='bg-white rounded-[6px] p-6 w-full md:w-1/2 shadow-[0px_1px_14px_0px_rgba(0,7,22,0.14),0px_0px_2px_0px_rgba(65,77,92,0.20)]'>
              <h3 className='text-xl font-semibold mb-2'>Get Support</h3>
              <p className='text-gray-700 mb-4'>
                With technical issues, troubleshooting software and hardware problems and managing access permissions.
              </p>
              <button className='text-[#141C25] font-semibold text-sm leading-[30px] font-[Segoe UI] flex items-center gap-1.5 justify-center border-2.5 border-solid border-[#E3F1FB] bg-[#E3F1FB] rounded-full px-3 py-2.5'>
                Get support <span>&#8594;</span>
              </button>

            </div>

            {/* Raise a Digital Business Improvement Card */}
            <div className='bg-white rounded-[6px] p-6 w-full md:w-1/2 shadow-[0px_1px_14px_0px_rgba(0,7,22,0.14),0px_0px_2px_0px_rgba(65,77,92,0.20)]'>
              <h3 className='text-xl font-semibold mb-2'>Raise a Digital Business Improvement</h3>
              <p className='text-gray-700 mb-4'>
                The first step in having your Digital or ICT business improvement tested and assessed for a new initiative/technology, renewal, and enhance existing.
              </p>
              <button
                className='text-[#141C25] font-semibold text-sm leading-[30px] font-[Segoe UI] flex items-center gap-1.5 justify-center border-2.5 border-solid border-[#E3F1FB] bg-[#E3F1FB] rounded-full px-3 py-2.5'
                onClick={() => { navigate('/support/digitalbusinessimprovementform')}}
              >
                Get started <span>&#8594;</span>
              </button>
            </div>
          </div>
        </div>

        {/* =================================== */}
        {/*     Recommended apps for you        */}
        {/* =================================== */}

        <div className='flex flex-col mt-[25px]'>
          <div>
            <h3 className='text-2xl font-semibold mb-2'>Recommended apps for you</h3>
            <p className='font-light  text-lg mb-1'>Personalised suggestions to enhance your digital experience</p>
            <a href='/store/catalogue' className='text-[#0078D4] mt-0 text-sm'> View Catalog <span>&#8594;</span></a>
          </div>
          <div>

            <div className='relative'>

              {/* Display the arrow only if the content is scrollable */}
              {canScrollLeft && (
                <div
                  className='absolute left-[-65px] top-1/2 -translate-y-1/2 translate-x-4 z-100 bg-[#F5F5F5] w-10 h-10 rounded-full p-2 shadow-md hover:bg-gray-50 transition-colors hidden md:flex items-center justify-center  cursor-pointer'
                  aria-label='Scroll left'
                  onClick={scrollLeft}
                >
                  <span>&#8592;</span>
                </div>
              )}
              <div
                ref={recommendedAppRef}
                className='mt-4 flex flex-row gap-4 overflow-x-auto overflow-y-hidden whitespace-nowrap scroll-smooth no-scrollbar pb-3 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]'
                onScroll={handleScroll}
              >
                {
                  recommendedApps.map((app, index) => (
                    <AppCard
                      key={index}
                      index={index}
                      app={app}
                    />
                  ))
                }
              </div>

              {/* Display the arrow only if the content is scrollable */}
              {canScrollRight && (
                <div
                  className='absolute right-[-35px] top-1/2 -translate-y-1/2 translate-x-4 z-100 bg-[#F5F5F5] w-10 h-10 rounded-full p-2 shadow-md hover:bg-gray-50 transition-colors hidden md:flex items-center justify-center cursor-pointer'
                  aria-label='Scroll right'
                  onClick={scrollRight}

                >
                  <span>&#8594;</span>
                </div>
              )}
            </div>

          </div>
        </div>

        {/* =============== */}
        {/*     Category    */}
        {/* =============== */}

        <div className='flex flex-col mt-[25px]'>
          <div>
            <h3 className='text-2xl font-semibold mb-2'>Categories</h3>
            <a href='/store/catalogue' className='text-[#0078D4] mt-0 text-sm'> View all <span>&#8594;</span></a>
          </div>

          <div className='mt-4 flex flex-col md:flex-row gap-3 mb-4'>

            <div className='flex px-5 py-3 justify-center items-center gap-[12px] flex-shrink-0 rounded-[6px] bg-[#E9EBED]'>
              <h6 className='mb-0'>Productivity & Collaboration</h6>
            </div>

            <div className='flex px-5 py-3 justify-center items-center gap-[12px] flex-shrink-0 rounded-[6px] bg-[#E9EBED]'>
              <h6 className='mb-0'>Design & Modelling</h6>
            </div>


            <div className='flex px-5 py-3 justify-center items-center gap-[12px] flex-shrink-0 rounded-[6px] bg-[#E9EBED]'>
              <h6 className='mb-0'>Cost Planning</h6>
            </div>


            <div className='flex px-5 py-3 justify-center items-center gap-[12px] flex-shrink-0 rounded-[6px] bg-[#E9EBED]'>
              <h6 className='mb-0'>Construction Management</h6>
            </div>

          </div>
        </div>
      </div >
  )
}