//================================================================
//  Component: Tab View
//================================================================

//  Purpose: Simple way to build a tab view for content

//  Properties:
//    - defaultView = {A string, default view for the tabular view}
//    - oneTitle = {A string, title of the tab}
//    - oneTag = {(OPTIONAL) A string, count tag for tab}
//    - oneContent = {HTML, pass in the content}
//    - twoTitle = {A string, title of the tab}
//    - twoTag = {(OPTIONAL) A string, count tag for tab}
//    - twoContent = {HTML, pass in the content}
//    - threeTitle = {A string, title of the tab}
//    - threeTag = {(OPTIONAL) A string, count tag for tab}
//    - threeContent = {HTML, pass in the content}
//    - fourTitle = {A string, title of the tab}
//    - fourTag = {(OPTIONAL) A string, count tag for tab}
//    - fourContent = {HTML, pass in the content}

//  Example:
//    <TabView
//      defaultView={1}
//      oneTitle={"OVERVIEW"}
//      oneContent={<div>Overview content</div>}
//      twoTitle={"PRICING"}
//      twoContent={<div>Pricing content</div>}
//      threeTitle={"SUPPORT"}
//      threeContent={<div>Support content</div>}
//      fourTitle={"TERMS OF SERVICE"}
//      fourContent={<div>Terms of service content</div>}
//    ></TabView>    

//================================================================

//Libraries
import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router";
import { useSearchParams } from 'react-router-dom';


export default function TabView(props) {

  //------------------------------------------------------
  //  Extract Props
  //------------------------------------------------------

    const defaultView = props.defaultView
    const oneTitle = props.oneTitle
    const oneTag = props.oneTag
    const oneContent = props.oneContent
    const twoTitle = props.twoTitle
    const twoTag = props.twoTag
    const twoContent = props.twoContent
    const threeTitle = props.threeTitle
    const threeTag = props.threeTag
    const threeContent = props.threeContent
    const fourTitle = props.fourTitle
    const fourTag = props.fourTag
    const fourContent = props.fourContent

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const navigate = useNavigate();
    const location = useLocation();  
    const [searchParams] = useSearchParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    //Visibilty of content > depends on click event for each tab
    const [oneHidden, setOneHidden] = useState(true);
    const [twoHidden, setTwoHidden] = useState(true);
    const [threeHidden, setThreeHidden] = useState(true);
    const [fourHidden, setFourHidden] = useState(true);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Toggles between visible/hidden content
    function HandleHidden(eventId){     
  
      //Set new active tab
      let newActiveTabTitle;
      if (eventId === '1'){
        newActiveTabTitle = oneTitle?.replace(" ", "")?.toLowerCase();
        setOneHidden(false);
        setTwoHidden(true);
        setThreeHidden(true);
        setFourHidden(true);

      }
      if (eventId === '2'){
        newActiveTabTitle = twoTitle?.replace(" ", "")?.toLowerCase();
        setOneHidden(true);
        setTwoHidden(false);
        setThreeHidden(true);
        setFourHidden(true);
      }
      if (eventId === '3'){
        newActiveTabTitle = threeTitle?.replace(" ", "")?.toLowerCase();
        setOneHidden(true);
        setTwoHidden(true);
        setThreeHidden(false);
        setFourHidden(true);
      }
      if (eventId === '4'){
        newActiveTabTitle = fourTitle?.replace(" ", "")?.toLowerCase();
        setOneHidden(true);
        setTwoHidden(true);
        setThreeHidden(true);
        setFourHidden(false);
      }

      //Check if there is no default view > Update URL
      if(defaultView === undefined) return;

      //Check the previously active tab
      let previousActiveTabTitle;
      if(!oneHidden) previousActiveTabTitle = oneTitle?.replace(" ", "")?.toLowerCase();
      if(!twoHidden) previousActiveTabTitle = twoTitle?.replace(" ", "")?.toLowerCase();
      if(!threeHidden) previousActiveTabTitle = threeTitle?.replace(" ", "")?.toLowerCase();
      if(!fourHidden) previousActiveTabTitle = fourTitle?.replace(" ", "")?.toLowerCase();

      //Extract base URL and params > Set new URL
      const baseUrl = location.pathname;
      const baseParams = searchParams.toString();
      const newParams = baseParams.replace(previousActiveTabTitle, newActiveTabTitle);
      const newUrl = `${baseUrl}?${newParams}`;

      //Update the URL
      navigate(newUrl, { replace: true });

    };

    function NavStyle(id){

      //Nav item 1 style
      if(id === '1' && oneHidden){
        return 'cursor-pointer p-[10px]'; // Default Style
      } else if(id === '1' && !oneHidden) {
        return 'cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 p-[10px]'; // Selected Style border-b-[2px solid var(--teal)]
      }

      //Nav item 2 style
      if(id === '2' && twoHidden){
        return 'cursor-pointer p-[10px]'; // Default Style
      } else if(id === '2' && !twoHidden) {
        return 'cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 p-[10px]'; // Selected Style border-b-[2px solid var(--teal)]
      }

      //Nav item 3 style
      if(id === '3' && threeHidden){
        return 'cursor-pointer p-[10px]'; // Default Style
      }else if(id === '3' && !threeHidden) {
        return 'cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 p-[10px]'; // Selected Style border-b-[2px solid var(--teal)]
      }

      //Nav item 4 style
      if(id === '4' && fourHidden){
        return 'cursor-pointer p-[10px]'; // Default Style
      }else if(id === '4' && !fourHidden) {
        return 'cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 p-[10px]'; // Selected Style border-b-[2px solid var(--teal)]
      }

    };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  //Used to handle the page view depending on the route
  useEffect(() => {

    if(defaultView === 1) return setOneHidden(false);
    if(defaultView === 2) return setTwoHidden(false);
    if(defaultView === 3) return setThreeHidden(false);
    if(defaultView === 4) return setFourHidden(false);

  }, [defaultView])


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='w-full'>
        {/* Nav Bar */}
        <div className='flex flex-row gap-3 border-[#D8D8D8] border-b mt-2 px-[30px] h-[50px]'>
          <div className={NavStyle('1')} id='1' onClick={(e) => HandleHidden(e.target.id)}>
            {oneTitle}
            {oneTag > 0 && 
              <label className='bg-black text-white rounded-full min-w-5 h-5 px-1.5 place-content-center text-sm ml-2'>
                {oneTag}
              </label>
            }
            </div>
          <div className={NavStyle('2')} id='2' onClick={(e) => HandleHidden(e.target.id)}>
            {twoTitle}
            {twoTag > 0 && 
              <label className='bg-black text-white rounded-full min-w-5 h-5 px-1.5 place-content-center text-sm ml-2'>
                {twoTag}
              </label>
            }
            </div>
          <div className={NavStyle('3')} id='3' onClick={(e) => HandleHidden(e.target.id)}>
            {threeTitle}
            {threeTag > 0 && 
              <label className='bg-black text-white rounded-full min-w-5 h-5 px-1.5 place-content-center text-sm ml-2'>
                {threeTag}
              </label>
            }
            </div>
          <div className={NavStyle('4')} id='4' onClick={(e) => HandleHidden(e.target.id)}>
            {fourTitle}
            {fourTag > 0 && 
              <label className='bg-black text-white rounded-full min-w-5 h-5 px-1.5 place-content-center text-sm ml-2'>
                {fourTag}
              </label>
            }
            </div>
        </div>

        {/* Content */}
        <div className='mx-[0%] my-[2%]'>
          <div hidden={oneHidden}>{oneContent}</div>
          <div hidden={twoHidden}>{twoContent}</div>
          <div hidden={threeHidden}>{threeContent}</div>
          <div hidden={fourHidden}>{fourContent}</div>
        </div>
    </div>
  )
}
