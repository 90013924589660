//================================================================
//  Page: CatalogueDetail 
//================================================================

//  Purpose: This page displays the catalogue item in more detail

//================================================================

//Libraries
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../Components/Breadcrumbs/BreadCrumbs';

//Functions
import DocumentListener from '../../Library/DocumentListener';

//Images
import ArrowUp from '../../Components/Images/Icon_Angle_Up.svg';
import ArrowDown from '../../Components/Images/Icon_Angle_Down.svg';

//CSS
import './Catalogue.css';

export default function CatalogueDetail() {

    //------------------------------------------------------
    //  useContexts & React Router
    //------------------------------------------------------

    const { id: catalogueId } = useParams();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------    
   
    // Used to save the catalogue item
    const [catalogueDoc, setCatalogueDoc] = useState(undefined);

    // Used to save the page status > 'pending', 'onload', 'success', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // State to track whether the full text is shown or truncated
    const [isExpanded, setIsExpanded] = useState(false);

    // Check if any tags have values
    const hasTags = catalogueDoc?.tags?.businessunit.length > 0 || catalogueDoc?.tags?.region.length > 0 || catalogueDoc?.tags?.applicationcategories.length > 0;

    // Define the maximum number of characters to show when the text is collapsed
    const MAX_LENGTH = 150;

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function to toggle between expanded and collapsed text
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get Catalogue Item
    useEffect(() => {

        function onLoad(document) {
            setCatalogueDoc(document);
            setPageStatus('onload');

        }

        function onChange(document) {
            setCatalogueDoc(document);
            setPageStatus('onload');

        }

        function onError(error) {
            console.log('error', error)
            setPageStatus('error-fatal');

        }

        const unsubscribe = DocumentListener('catalogueitems', catalogueId, onLoad, onChange, onError);

        return () => {
            unsubscribe();
        }

    }, [catalogueId]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='container  flex flex-col gap-2 px-4 py-4 md:px-[3%] md:py-[2%] w-full'>

            <PageComponent
                header={
                    <BreadCrumbs rootLinkName={'Store'} rootLinkRoute={'/'} childLinkName={catalogueDoc?.application?.applicationname}></BreadCrumbs>
                }
                status={pageStatus}
                body={
                    <div>
                        <div className='flex gap-6 py-3 md:py-4 border-b-[1px] border-b-[#D8D8D8]'>

                            {/* Icon */}
                            <div className='flex justify-center align-middle '>
                                <img className='h-[100px] w-[100px] md:h-[120px] md:w-[120px] rounded' src={catalogueDoc?.iconurl} alt='product-icon'></img>
                            </div>

                            {/* Title */}
                            <div className='flex flex-col justify-center align-middle '>
                                <h1 className='text-black font-semibold text-[24px] leading-[32px]'>{catalogueDoc?.application?.applicationname}</h1>
                                <p className='text-[16px] leading-[24px] font-normal'>{catalogueDoc?.vendor?.vendorname}</p>
                            </div>

                        </div>

                        <div className='grid grid-cols-12 gap-4 md:gap-6 py-4'>

                            {/* Description */}
                            <div className='col-span-12 md:col-span-8 flex flex-col gap-2 md:gap-3'>
                                <p className='text-[21px] leading-[32px] font-semibold mb-0'>
                                    About this app
                                </p>

                                {/* Description text: shows full or truncated based on isExpanded */}
                                <p className='text-[16px] leading-[24px] font-normal mb-0'>
                                    {
                                        isExpanded
                                        ? catalogueDoc?.description
                                        : catalogueDoc?.description?.slice(0, MAX_LENGTH) +
                                        (catalogueDoc?.description?.length > MAX_LENGTH ? '...' : '')
                                    }
                                </p>

                                {/* Read More/Show Less button: appears only if text exceeds MAX_LENGTH */}
                                {
                                    catalogueDoc?.description?.length > MAX_LENGTH && 
                                    (
                                    <button onClick={toggleReadMore} className='mt-2 self-start'>
                                        {
                                            isExpanded ?
                                            (
                                                <div className='flex gap-1 text-[#0972D3] cursor-pointer items-center'>
                                                    <p className='align-middle m-0'>Read less</p>
                                                    <img className='w-4 h-4' src={ArrowUp} alt='arrow-up'></img>
                                                </div>
                                            )
                                            :
                                            (
                                                <div className='flex gap-1 text-[#0972D3] cursor-pointer items-center'>
                                                    <p className='align-middle m-0'>Read more</p>
                                                    <img className='w-4 h-4' src={ArrowDown} alt='arrow-down'></img>
                                                </div>
                                            )
                                        }
                                    </button>
                                )}
                            </div>

                            <div className='col-span-12 md:col-span-4 flex flex-col gap-[36px]'>

                                {/* Pricing */}
                                <div className='flex flex-col gap-3'>
                                    <p className='text-[21px] leading-[32px] font-semibold mb-0'>
                                        Pricing
                                    </p>
                                    <p className='text-[16px] leading-[24px] font-normal mb-0'>
                                        {catalogueDoc?.pricing?.amount} {catalogueDoc?.pricing?.currency} / {catalogueDoc?.pricing?.frequency}
                                    </p>
                                </div>

                                {/* Supporting Links */}
                                <div className='flex flex-col gap-3'>
                                    <p className='text-[21px] leading-[32px] font-semibold mb-0'>
                                        Supporting Links
                                    </p>
                                    {
                                        catalogueDoc?.supportlinks?.length === 0 ?
                                        <p className='text-[16px] leading-[24px] font-normal mb-0'>N/A</p>
                                        :
                                        catalogueDoc?.supportlinks?.map((link, index) => {
                                            return (
                                                <p key={index} className='text-[16px] leading-[24px] font-normal mb-0 '>
                                                    <a className='no-underline' href={link.url} target='_blank' rel='noreferrer'>{link.url}</a>
                                                </p>
                                            )
                                        })
                                    }
                                </div>

                                {/* Tags */}
                                <div className='flex flex-col gap-3'>
                                    <p className='text-[21px] leading-[32px] font-semibold mb-0'>
                                        Tags
                                    </p>

                                    {
                                        hasTags ? 
                                        (
                                            <div className='flex flex-row flex-wrap gap-2'>

                                                {
                                                    catalogueDoc?.tags?.businessunit?.map((tag, index) => (
                                                        <div key={`businessunit-${index}`} className='flex px-3 py-1 justify-center items-center gap-1.5 rounded-[30px] bg-[var(--neutral-colours-e-9-ebed-default-background-disabled,#E9EBED)] text-sm'>
                                                            {tag}
                                                        </div>
                                                    ))
                                                }

                                                {
                                                    catalogueDoc?.tags?.region?.map((tag, index) => (
                                                        <div key={`region-${index}`} className='flex px-3 py-1 justify-center items-center gap-1.5 rounded-[30px] bg-[var(--neutral-colours-e-9-ebed-default-background-disabled,#E9EBED)] text-sm'>
                                                            {tag}
                                                        </div>
                                                    ))
                                                }

                                                {
                                                    catalogueDoc?.tags?.applicationcategories?.map((tag, index) => (
                                                        <div key={`applicationcategories-${index}`} className='flex px-3 py-1 justify-center items-center gap-1.5 rounded-[30px] bg-[var(--neutral-colours-e-9-ebed-default-background-disabled,#E9EBED)] text-sm'>
                                                            {tag}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ) 
                                        :
                                        (
                                            <p className='text-[16px] leading-[24px] font-normal mb-0'>NA</p>
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                    </div>}
            ></PageComponent>

        </div>
    )
}