//================================================================
//  Page: RequestDetails 
//================================================================

//  Purpose: Detailed Request page

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import SideNav from '../../../Components/SideNav/SideNav';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadCrumbs';
import TabView from '../../../Components/TabView/TabView';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import HistoryTimeline from '../../../Components/HistoryTimeline/HistoryTimeline';
import CommentsThread from '../../../Components/CommentsThread/CommentsThread';

//Functions
import DocumentListener from '../../../Library/DocumentListener';
import ConvertDate from '../../../Library/ConvertDate';
import WriteDocument from '../../../Library/WriteDocument';

//Images
import FileIcon from '../../../Components/Images/Icon_File_Black.svg';

//CSS
import '../Account.css';

export default function RequestDetails() {

    //------------------------------------------------------
    //  useContexts & React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const params = useParams();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    const [requestDoc, setRequestDoc] = useState(undefined);
    const [requestStatus, setRequestStatus] = useState('');
    const [changeStatusComment, setChangeStatusComment] = useState('');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    function handleSubmit() {

        if (requestStatus === 'Escalate') { //check what this does

            const newActivityFeed = [...requestDoc.activityfeed, {
                'action': 'escalated',
                'actionedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'activitydate': new Date(),
                'comments': changeStatusComment
            }]


            return WriteDocument('tasks', requestDoc?.taskid, { 'activityfeed': newActivityFeed }, true)
                .then(() => {
                    setRequestStatus('');
                    setChangeStatusComment('');
                })
                .catch((err) => {
                    console.log(err);
                    //TODO add toast
                })

        }

        if (requestStatus === 'Cancel') {

            const newActivityFeed = [...requestDoc.activityfeed, {
                'action': 'cancelled',
                'actionedby': {
                    'email': getUser.emailaddress,
                    'givenname': getUser.givenname,
                    'surname': getUser.surname,
                },
                'activitydate': new Date(),
                'comments': changeStatusComment
            }]

            const docObject = {
                'status': 'Cancelled',
                'activityfeed': newActivityFeed,
            }

            return WriteDocument('tasks', requestDoc?.taskid, docObject, true)
                .then(() => {
                    setRequestStatus('');
                    setChangeStatusComment('');
                })
                .catch((err) => {
                    console.log(err);
                    //TODO add toast
                })
        }

    }


    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get all tasks
    useEffect(() => {

        const taskid = params.id;

        function onLoadChange(document) {

            setRequestDoc(document);

        }

        function onError(error) {
            console.log(error);
            // TODO Add Toast
        }

        const unsubscribe = DocumentListener('tasks', taskid, onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        }
    })

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-row overflow-y-hidden'>
            <SideNav />

            <div className='flex flex-col w-full'>

                <PageComponent
                    header={
                        <div className='flex flex-col p-4 bg-neutral-100 h-32 w-full border-solid border-1 border-neutral-300'>
                            <BreadCrumbs rootLinkName={'My Requests'} rootLinkRoute={'/requests'} childLinkName={requestDoc?.requestid?.toUpperCase()} />
                            <h4 className='flex items-center h-full cursor-default'>{requestDoc?.taskname}</h4>
                        </div>}
                    body={


                        <div className='Request-Layout'>

                            {/* MAIN PAGE CONTENT-REQUEST INFORMATION */}
                            <div className='Request-Summary overflow-y-auto p-8'>

                                <label className='font-semibold h-12 flex items-center'>Name</label>
                                <p className='h-12 flex items-center'>{requestDoc?.taskname}</p>

                                <label className='font-semibold h-12 flex items-center'>Short Description</label>
                                <p className='h-12 flex items-center'>{requestDoc?.taskdescription}</p>

                                <label className='font-semibold h-12 flex items-center'>Assigned to</label>
                                <div className='flex flex-col h-24 py-4'>
                                    <p className='h-2 flex items-center'>{requestDoc?.assignedto.givenname} {requestDoc?.assignedto.surname}</p>
                                    <p className='h-2 flex items-center text-sm text-[#424242]'>{requestDoc?.assignedto.email}</p>
                                </div>

                                <label className='font-semibold h-12 flex items-center'>Status</label>
                                <StatusLabel status={requestDoc?.status} />

                                <label className='font-semibold h-12 flex items-center'>Created Date</label>
                                <p className='h-12 flex items-center'>{ConvertDate(requestDoc?.createddate)}</p>

                                <label className='font-semibold h-12 flex items-center'>Uploaded Documents</label>
                                <div className='flex flex-col min-h-12 border-1 rounded-md'>
                                    {requestDoc?.supportingdocs && requestDoc.supportingdocs.length > 0 ?
                                        requestDoc.supportingdocs.map((doc, index) => (
                                            <div key={index} className='flex flex-row'>
                                                <img className='h-6 my-auto mx-2' alt='File' src={FileIcon} />
                                                <a className='h-12 flex items-center cursor-pointer' target='_blank' rel='noopener noreferrer' href={doc.fileUrl}>{doc.fileName}</a>
                                            </div>
                                        ))
                                        :
                                        <p className='my-auto mx-2'>N/A</p>
                                    }
                                </div>

                                {/* REQUEST HISTORY & NOTES */}
                                <div>

                                    <TabView
                                        defaultView={1}
                                        oneTitle={'History'}
                                        oneContent={
                                            requestDoc?.activityfeed?.toReversed().map((item, index) => (
                                                <HistoryTimeline
                                                    item={item}
                                                    key={index}
                                                ></HistoryTimeline>
                                            ))}
                                        twoTitle={'Notes'}
                                        twoContent={
                                            <CommentsThread task={requestDoc}></CommentsThread>}
                                    ></TabView>
                                </div>

                            </div>

                            {/* CHANGE REQUEST STATUS */}

                            <div className='Manage-Request flex flex-col justify-between overflow-y-auto'>

                                <div>

                                    <label className='p-4 font-semibold text-lg border-b-neutral-300 border-b-2 w-full'>Manage Request</label>

                                    <div className='flex flex-col p-4 gap-4'>

                                        <label className='font-semibold'> Change status:</label>

                                        <select
                                            className='Input-Field-Select'
                                            value={requestStatus}
                                            disabled={requestDoc?.status !== 'In Progress'}
                                            onChange={(e) => setRequestStatus(e.target.value)}
                                        >
                                            <option hidden value=''>{requestDoc?.status !== 'In Progress' ? requestDoc?.status : 'Select'}</option>
                                            <option value='Escalate'>Escalate</option>
                                            <option className='text-[#DE0000]' value='Cancel'>Cancel</option>
                                        </select>

                                        {/* Only shows when an option is selected */}
                                        <div hidden={requestStatus.length === 0} className='flex flex-col gap-4'>
                                            <label className='font-semibold'> Comment: <span className='text-[#C4314B]'>*</span> </label>
                                            <textarea
                                                className='Input-Field-TextArea'
                                                placeholder={`Add a reason for ${requestStatus === 'Escalate' ? 'escalating' : 'cancelling'} this request...`}
                                                value={changeStatusComment}
                                                onChange={(e) => setChangeStatusComment(e.target.value)}
                                            ></textarea>
                                        </div>

                                    </div>

                                </div>

                                <div className='border-t-neutral-300 border-t-2 w-full place-self-end p-4 flex flex-row gap-4'>
                                    <button className='Primary-Button' disabled={changeStatusComment.length === 0} onClick={() => handleSubmit()}>Proceed</button>
                                    <button className='Secondary-Button' onClick={() => {
                                        setRequestStatus('');
                                        setChangeStatusComment('');
                                    }}>Cancel</button>
                                </div>

                            </div>
                        </div>}
                ></PageComponent>

            </div>

        </div>
    )
}