//================================================================
//  Component: SideNav
//================================================================

//  Purpose: Contains the side menu 

//  Example:
//    <SideNav></SideNav>

//================================================================


//Libraries
import React, { useEffect, useContext, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';


export default function SideNav() {

    //------------------------------------------------------
    //  React Router & useContext
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const location = useLocation();

    const header = 'Account';

    const menu = {
        'Subscriptions': [{
            'title': 'View My Subscriptions',
            'link': '/subscriptions'
        }],
        'Requests': [{
            'title': 'View My Requests',
            'link': '/requests'
        }],
        'Approvals': [{
            'title': 'View My Approvals',
            'link': '/approvals'
        }, {
            'title': 'Delegate Settings',
            'link': '/approvals/delegate'
        }],
        'Teams': [{
            'title': 'View My Teams',
            'link': '/teams'
        }],
        'Admin': [{
            'title': 'Permission Sets',
            'link': '/admin'
        }]
    };

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store number of tasks
    const [taskCount, setTaskCount] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'requests': '',
            'approvals': '',
            'subscriptions': '',
        }
    );

    //------------------------------------------------------
    //  Fuctions
    //------------------------------------------------------

    // Gets total requests for each Nav item
    function getCount(item) {
        if (item.title === 'View My Requests') {
            return taskCount.requests
        }

        if (item.title === 'View My Approvals') {
            return taskCount.approvals
        }
    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get all tasks
    useEffect(() => {

        // Get number of remaining tasks for 'Requests' and 'Approvals' tabs
        const queries = [
            // Get requests
            [
                ['createdby.email', '==', getUser.emailaddress], 
                ['status', '==', 'In Progress'], 
                ['tasktype', '==', 'Business Request']
            ], 
            // Get approvals
            [
                ['assignedto.email', '==', getUser.emailaddress], 
                ['status', '==', 'In Progress'], 
                ['tasktype', 'in', ['Line Manager Approval', 'Finance Manager Approval', 'Commercial Second Round Approval', 'Corporate Approval']]
            ]
            // add subscription count
        ]
        const promises = [];

        queries.forEach((query) => {
            promises.push(
                QueryDocument('tasks', query)
            )
        })

        // Settle promises
        Promise.all(promises)
            .then((result) => {

                setTaskCount({
                    'requests': result[0].length,
                    'approvals': result[1].length,
                });

            })
            .catch((error) => {

                console.log('error', error);

            })

        // eslint-disable-next-line
    }, [getUser])

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col gap-2 py-[25px] px-[30px] border-solid border-gray-200 border-1 h-full min-h-[calc(100vh-130px)] overflow-y-auto overflow-x-hidden'>

            {/* ----------------------------------------------- */}
            {/*    HEADER                                       */}
            {/* ----------------------------------------------- */}

            <h4 className='text-[24px] pb-2'>{header}</h4>

            {/* ----------------------------------------------- */}
            {/*    BODY                                         */}
            {/* ----------------------------------------------- */}

            <div className='flex flex-col gap-3'>
                {
                    Object.entries(menu).map(([key, items], index) => (
                        <div className='w-full flex flex-col gap-2' key={index}>

                            {/* Link to parent page */}
                            <Link className='no-underline text-black px-3' to={items[0].link}>
                                <p className='font-semibold text-lg cursor-pointer m-0'>{key}</p>
                            </Link>

                            {/*  Link to child page */}
                            <div className='cursor-pointer'>
                                {
                                    items.map((item, i) => (
                                        <Link key={i} className={`no-underline text-black py-2 px-3 hover:bg-[#E3F1FB] whitespace-nowrap flex items-center text-base ${location?.pathname.includes(item?.link) ? 'bg-[#E3F1FB]' : 'bg-[#FFFFFF]'}`} to={item?.link}>
                                            
                                            {/* Title */}
                                            {item?.title}

                                            {/* Total Count */}
                                            <div className={`px-2 mx-2 text-[14px] rounded-[50%] ${location?.pathname.includes(item?.link) ? 'bg-[#FFFFFF]' : 'bg-[#E3F1FB]'}`}>
                                                {getCount(item)}
                                            </div>

                                        </Link>
                                    ))
                                }
                            </div>

                        </div>
                    ))
                }
            </div>

            {/* ----------------------------------------------- */}
            {/*    FOOTER                                       */}
            {/* ----------------------------------------------- */}

            <hr className='pl-3 border text-[#D8D8D8]'></hr>

            <div className='text-[#C4314B] pl-3 cursor-pointer' onClick={() => console.log(location)}>
                Sign Out
            </div>

        </div>
    )
}
