//================================================================
//  Page: Requests 
//================================================================

//  Purpose: View past and present requests

//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import SideNav from '../../../Components/SideNav/SideNav';
import TabView from '../../../Components/TabView/TabView';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';

//Functions
import QueryListener from '../../../Library/QueryListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images

//CSS
import '../Account.css';

export default function Requests() {

    //------------------------------------------------------
    //  useContexts & React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const navigate = useNavigate();


    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    const [pendingRequests, setPendingRequests] = useState([]);
    const [requestHistory, setRequestHistory] = useState([]);
    const [currentFilter, setCurrentFilter] = useState('All');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------


    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get all tasks
    useEffect(() => {
        function onLoadChange(documents) {
            setPendingRequests(documents.filter((request) => (request.status === 'In Progress')));
            setRequestHistory(documents.filter((request) => (
                currentFilter !== 'All' ? request.status === currentFilter && request.status !== 'In Progress' : request.status !== 'In Progress'
            )));

        }

        function onError(error) {
            console.log(error);
            // TODO Add Toast
        }

        const unsubscribe = QueryListener('tasks', [['createdby.email', '==', getUser.emailaddress], ['tasktype', '==', 'Business Request']], onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        }

    // eslint-disable-next-line
    }, [requestHistory])

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='Account-Container'>
            
            <div className='Account-Body'>
                <PageComponent
                    header={
                        <div className='flex flex-col bg-neutral-100 h-32 w-full border-solid border-1 border-neutral-300'>
                            <h4 className='flex p-4 items-center h-full cursor-default'>My Requests</h4>
                        </div>
                    }
                    body={
                        <TabView
                            defaultView={1}
                            oneTitle={'Pending'}
                            oneContent={

                                <div className='px-8'>

                                    {/* ----------------------------------- */}
                                    {/*  Table Container                    */}
                                    {/* ----------------------------------- */}

                                    <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

                                        {/* ======== Header ========== */}
                                        <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                                            <label className='font-semibold self-center'>
                                                Total: {pendingRequests.length} of {pendingRequests.length}
                                            </label>

                                        </div>

                                        {/* ======== Table Scrollable ========== */}
                                        <div className='w-full overflow-auto min-h-[350px]'>

                                            <table className='w-full'>
                                                <thead>
                                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2 z-10 box-border overflow-visible'>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Request ID</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Name</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Short Description</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Status</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Created</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // No agreements found
                                                        pendingRequests.length === 0 ?
                                                            (
                                                                <tr>
                                                                    <td colSpan={7}>
                                                                        <div className='text-center p-[2%]'>
                                                                            No requests found.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            :
                                                            (
                                                                // Map in each commercial request
                                                                pendingRequests.map((request, index) => (
                                                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>

                                                                        {/* Request ID */}
                                                                        <td className='py-[15px] px-[20px] font-semibold left-0 bg-white whitespace-nowrap sticky cursor-pointer underline' onClick={() => navigate(`/requests/${request?.taskid}`)}>
                                                                            {request?.requestid?.toUpperCase()}
                                                                        </td>

                                                                        {/* Name */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            {request?.taskname}
                                                                        </td>

                                                                        {/* Short Description */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            {request?.taskdescription}
                                                                        </td>

                                                                        {/* Status */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            <StatusLabel status={request?.status}></StatusLabel>
                                                                        </td>

                                                                        {/* Created Date */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            {ConvertDate(request?.createddate)}
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            )
                                                    }
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>

                                </div>

                            }
                            twoTitle={'History'}
                            twoContent={
                                <div className='px-8'>

                                    {/* ======== Status (Buttons) ========== */}
                                    <div className='flex flex-row items-center gap-2 mb-4'>

                                        {/* Approved */}
                                        <button className={currentFilter === 'Completed' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Completed')}>
                                            Approved
                                        </button>

                                        {/* Rejected */}
                                        <button className={currentFilter === 'Rejected' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Rejected')}>
                                            Rejected
                                        </button>

                                        {/* All */}
                                        <button className={currentFilter === 'All' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('All')}>
                                            All
                                        </button>

                                    </div>

                                    {/* ----------------------------------- */}
                                    {/*  Table Container                    */}
                                    {/* ----------------------------------- */}

                                    <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

                                        {/* ======== Header ========== */}
                                        <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                                            <label className='font-semibold self-center'>
                                                Total: {requestHistory.length} of {requestHistory.length}
                                            </label>

                                        </div>

                                        {/* ======== Table Scrollable ========== */}
                                        <div className='w-full overflow-auto min-h-[350px]'>

                                            <table className='w-full'>
                                                <thead>
                                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2 z-10 box-border overflow-visible'>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Request ID</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Name</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Short Description</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Status</th>
                                                        <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Created</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // No agreements found
                                                        requestHistory.length === 0 ?
                                                            (
                                                                <tr>
                                                                    <td colSpan={7}>
                                                                        <div className='text-center p-[2%]'>
                                                                            No requests found.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            :
                                                            (
                                                                // Map in each commercial request
                                                                requestHistory.map((request, index) => (
                                                                    request?.status !== 'In Progress' &&
                                                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>

                                                                        {/* Request ID */}
                                                                        <td className='py-[15px] px-[20px] font-semibold left-0 bg-white whitespace-nowrap sticky cursor-pointer underline' onClick={() => navigate(`/requests/${request?.taskid}`)}>
                                                                            {request?.requestid?.toUpperCase()}
                                                                        </td>

                                                                        {/* Name */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            {request?.taskname}
                                                                        </td>

                                                                        {/* Short Description */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            {request?.taskdescription}
                                                                        </td>

                                                                        {/* Status */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            <StatusLabel status={request?.status}></StatusLabel>
                                                                        </td>

                                                                        {/* Created Date */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            {ConvertDate(request?.createddate)}
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            )
                                                    }                                                
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>

                                </div>
                            }
                        ></TabView>
                    }
                ></PageComponent>
            </div>
    
            <div className='Account-SideNav'>
                <SideNav></SideNav>
            </div>

        </div>
    )
}