//================================================================
//  Page: Approvals 
//================================================================

//  Purpose: My Approvals page - shows all the approvals for the current user

//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import SideNav from '../../../Components/SideNav/SideNav';
import TabView from '../../../Components/TabView/TabView';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import ActionMenu from '../../../Components/ActionMenu/ActionMenu';

//Functions
import QueryListener from '../../../Library/QueryListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';

//CSS
import '../Account.css';

export default function Approvals() {

    //------------------------------------------------------
    //  useContexts & React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const navigate = useNavigate();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to determine status of the page > 'pending', 'onload', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Sets data for Pending tab
    const [pendingRequests, setPendingRequests] = useState([]);
    const [pendingRequestsFiltered, setPendingRequestsFiltered] = useState([]);

    // Sets date for History tab
    const [requestHistory, setRequestHistory] = useState([]);
    const [requestHistoryFiltered, setRequestHistoryFiltered] = useState([]);

    // History tab filters > 'All', 'Approved', 'Rejected'
    const [currentFilter, setCurrentFilter] = useState('All');

    // Table Search Input
    const [searchInput, setSearchInput] = useState('');

    // List of requests for bulk approvals
    const [requestList, setRequestList] = useState([]);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value, table) {

        // Clear ALL filters
        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {

            if (table === 'pending') return setPendingRequestsFiltered(pendingRequests);
            if (table === 'history') return setRequestHistoryFiltered(requestHistory);

        };

        // Search Filter
        //  1. Request Id
        //  2. Task Name
        //  3. Requestor email
        //  4. Task description
        const searchResults = (table === 'pending' ? pendingRequests : requestHistory).filter((object) =>
            object?.requestid?.toLowerCase().includes(value.toLowerCase()) ||
            object?.taskname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.createdby?.email?.toLowerCase().includes(value.toLowerCase()) ||
            object?.taskdescription?.toLowerCase().includes(value.toLowerCase())
        );

        // Save the filtered search results to the useState
        if (table === 'pending') return setPendingRequestsFiltered(searchResults);
        if (table === 'history') return setRequestHistoryFiltered(searchResults);

    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get all tasks
    useEffect(() => {

        function onLoadChange(documents) {

            // Get all pending requests
            setPendingRequests([...documents.filter((request) => (request.status === 'In Progress'))]);
            setPendingRequestsFiltered([...documents.filter((request) => (request.status === 'In Progress'))]);

            // Apply current filters, otherwise apply all
            setRequestHistory(documents.filter((request) => (
                currentFilter !== 'All' ? request.status === currentFilter : request.status !== 'In Progress'
            )));
            setRequestHistoryFiltered(documents.filter((request) => (
                currentFilter !== 'All' ? request.status === currentFilter : request.status !== 'In Progress'
            )));

            setPageStatus('onload');

        }

        function onError(error) {

            console.log(error);

            //TODO - test this part
            setPageStatus('error-fatal');

        }

        const unsubscribe = QueryListener('tasks', [
            ['assignedto.email', '==', getUser.emailaddress],
            ['tasktype', 'in', ['Line Manager Approval', 'Finance Manager Approval', 'Commercial Second Round Approval', 'Corporate Approval']]
        ], onLoadChange, onLoadChange, onError, ['lastmodifieddate', 'desc']);

        return () => {
            unsubscribe();
        }

        // eslint-disable-next-line
    }, [currentFilter])

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='Account-Container'>

            <div className='Account-Body'>
                <PageComponent
                    status={pageStatus}
                    header={
                        <div className='flex flex-col bg-neutral-100 h-32 w-full border-solid border-1 border-neutral-300'>
                            <h4 className='flex p-4 items-center h-full cursor-default'>My Approvals</h4>
                        </div>
                    }
                    body={
                        <TabView
                            defaultView={1}
                            oneTitle={'Pending'}
                            oneTag={pendingRequests?.length}
                            oneContent={

                                <div className='px-8'>

                                    {/* ----------------------------------- */}
                                    {/*  Table Container                    */}
                                    {/* ----------------------------------- */}

                                    <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

                                        {/* ======== Header ========== */}
                                        <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                                            <label className='font-semibold self-center'>
                                                Total: {pendingRequestsFiltered?.length} of {pendingRequests?.length}
                                            </label>

                                            <div className='flex flex-row gap-2'>

                                                {/* ======== Search Bar ======== */}
                                                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                                                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                                    <label htmlFor='searchInput'>
                                                        <input
                                                            id='searchInput'
                                                            className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                                            type='text'
                                                            placeholder='Search'
                                                            onChange={(e) => handleSearch(e.target.value, 'pending')}
                                                            value={searchInput}
                                                            autoComplete='no'
                                                        ></input>
                                                    </label>
                                                </div>

                                                {/* ======== Action Menu ======== */}
                                                <ActionMenu
                                                    requestList={requestList}
                                                    setRequestList={setRequestList}
                                                    isDisabled={requestList.length === 0}
                                                ></ActionMenu>

                                            </div>

                                        </div>

                                        {/* ======== Table Scrollable ========== */}
                                        <div className='w-full overflow-auto min-h-[350px]'>


                                            <table className='w-full'>
                                                <thead>
                                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2 box-border overflow-visible z-0'>
                                                        <th></th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap left-0 bg-white sticky'>Request ID</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Name</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Short Description</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Requested By</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Status</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Modified</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Created</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // No tasks found
                                                        pendingRequestsFiltered?.length === 0 ?
                                                            (
                                                                <tr>
                                                                    <td colSpan={8}>
                                                                        <div className='text-center p-[2%]'>
                                                                            {pendingRequests?.length > 0 ? 'No approvals found.' : 'You have no pending approvals.'}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            :
                                                            (
                                                                // Map in each commercial request
                                                                pendingRequestsFiltered?.map((request, index) => (
                                                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>


                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            <label className='Checkbox-Container'>

                                                                                <input
                                                                                    type='checkbox'
                                                                                    id={request?.taskid}
                                                                                    onClick={() => {

                                                                                        // Add to bulk approval list
                                                                                        document.getElementById(request?.taskid).checked ?
                                                                                            setRequestList([...requestList,
                                                                                            {
                                                                                                'taskId': document.getElementById(request?.taskid).id,
                                                                                                'requestId': request?.requestid,
                                                                                                'activityFeed': request?.activityfeed,
                                                                                                'taskDescription': request?.taskdescription
                                                                                            }])
                                                                                            :
                                                                                            // Remove from approval list when the request is unchecked
                                                                                            setRequestList(requestList.filter(item => item.taskId !== document.getElementById(request?.taskid).id));

                                                                                    }}
                                                                                ></input>
                                                                                <span className={'Checkmark'}></span>
                                                                            </label>
                                                                        </td>

                                                                        {/* Request ID */}
                                                                        <td className='py-[15px] px-[20px] align-text-top font-semibold left-0 bg-white whitespace-nowrap sticky cursor-pointer underline' onClick={() => navigate(`/approvals/${request?.taskid}`)}>
                                                                            {request?.requestid?.toUpperCase()}
                                                                        </td>

                                                                        {/* Name */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {request?.taskname}
                                                                        </td>

                                                                        {/* Short Description */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {request?.taskdescription}
                                                                        </td>

                                                                        {/* Name */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {request?.createdby.email}
                                                                        </td>

                                                                        {/* Status */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            <StatusLabel status={request?.status}></StatusLabel>
                                                                        </td>

                                                                        {/* Modified Date */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {ConvertDate(request?.lastmodifieddate)}
                                                                        </td>

                                                                        {/* Created Date */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {ConvertDate(request?.createddate)}
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            )
                                                    }
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>

                                </div>

                            }
                            twoTitle={'History'}
                            twoContent={

                                <div className='px-8'>

                                    {/* ======== Status (Buttons) ========== */}
                                    <div className='flex flex-row items-center gap-2 mb-4'>

                                        {/* Approved */}
                                        <button className={currentFilter === 'Completed' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Completed')}>
                                            Approved
                                        </button>

                                        {/* Rejected */}
                                        <button className={currentFilter === 'Rejected' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Rejected')}>
                                            Rejected
                                        </button>

                                        {/* All */}
                                        <button className={currentFilter === 'All' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('All')}>
                                            All
                                        </button>

                                    </div>

                                    {/* ----------------------------------- */}
                                    {/*  Table Container                    */}
                                    {/* ----------------------------------- */}

                                    <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

                                        {/* ======== Header ========== */}
                                        <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                                            <label className='font-semibold self-center'>
                                                Total: {requestHistoryFiltered?.length} of {requestHistory?.length}
                                            </label>

                                            {/* ======== Search Bar ======== */}
                                            <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                                                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                                <label htmlFor='searchInput'>
                                                    <input
                                                        id='searchInput'
                                                        className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                                        type='text'
                                                        placeholder='Search'
                                                        onChange={(e) => handleSearch(e.target.value, 'history')}
                                                        value={searchInput}
                                                        autoComplete='no'
                                                    ></input>
                                                </label>
                                            </div>

                                        </div>

                                        {/* ======== Table Scrollable ========== */}
                                        <div className='w-full overflow-auto min-h-[350px]'>

                                            <table className='w-full'>
                                                <thead>
                                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2 z-10 box-border overflow-visible'>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Request ID</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Name</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Short Description</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Status</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Modified</th>
                                                        <th className='py-[15px] px-[20px] font-medium whitespace-nowrap'>Created</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // No approvals found
                                                        requestHistoryFiltered?.length === 0 ?
                                                            (
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <div className='text-center p-[2%]'>
                                                                            No approvals found.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            :
                                                            (
                                                                // Map in each approval
                                                                requestHistoryFiltered?.map((request, index) => (
                                                                    request?.status !== 'In Progress' &&
                                                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>

                                                                        {/* Request ID */}
                                                                        <td className='py-[15px] px-[20px] align-text-top font-semibold left-0 bg-white whitespace-nowrap sticky cursor-pointer underline' onClick={() => navigate(`/approvals/${request?.taskid}`)}>
                                                                            {request?.requestid?.toUpperCase()}
                                                                        </td>

                                                                        {/* Name */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {request?.taskname}
                                                                        </td>

                                                                        {/* Short Description */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {request?.taskdescription}
                                                                        </td>

                                                                        {/* Status */}
                                                                        <td className='py-[15px] px-[20px]'>
                                                                            <StatusLabel status={request?.status}></StatusLabel>
                                                                        </td>

                                                                        {/* Modified Date */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {ConvertDate(request?.lastmodifieddate)}
                                                                        </td>

                                                                        {/* Created Date */}
                                                                        <td className='py-[15px] px-[20px] align-text-top'>
                                                                            {ConvertDate(request?.createddate)}
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>}
                        ></TabView>
                    }
                ></PageComponent>
            </div>

            <div className='Account-SideNav'>
                <SideNav></SideNav>
            </div>

        </div>
    )
}