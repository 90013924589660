//================================================================
//  Page: ApprovalsM
//================================================================

//  Purpose: My Approvals page (Mobile Version) - shows all the approvals for the current user

//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import TabView from '../../../Components/TabView/TabView';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';

//Functions
import QueryListener from '../../../Library/QueryListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import ArrowIcon from '../../../Components/Images/Icon_LeftArrow_Black.svg';
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';

//CSS

export default function ApprovalsM() {

    //------------------------------------------------------
    //  useContexts & React Router
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const navigate = useNavigate();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to determine status of the page > 'pending', 'onload', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Sets data for Pending tab
    const [pendingRequests, setPendingRequests] = useState([]);
    const [pendingRequestsFiltered, setPendingRequestsFiltered] = useState([]);


    // Sets data for History tab
    const [requestHistory, setRequestHistory] = useState([]);
    const [requestHistoryFiltered, setRequestHistoryFiltered] = useState([]);

    // History tab filters > 'All', 'Approved', 'Rejected
    const [currentFilter, setCurrentFilter] = useState('All');

    // Search Input
    const [searchInput, setSearchInput] = useState('');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        // Clear ALL filters
        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {

            setPendingRequestsFiltered(pendingRequests);
            setRequestHistoryFiltered(requestHistory);

            return;

        };

        // Search Filter
        //  1. Request Id
        //  2. Task Name
        //  3. Requestor email
        //  4. Task description
        const searchResultsPending = pendingRequests?.filter((object) =>
            object?.requestid?.toLowerCase().includes(value.toLowerCase()) ||
            object?.taskname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.createdby?.email?.toLowerCase().includes(value.toLowerCase()) ||
            object?.taskdescription?.toLowerCase().includes(value.toLowerCase())
        );
        const searchResultsHistory = requestHistory?.filter((object) =>
            object?.requestid?.toLowerCase().includes(value.toLowerCase()) ||
            object?.taskname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.createdby?.email?.toLowerCase().includes(value.toLowerCase()) ||
            object?.taskdescription?.toLowerCase().includes(value.toLowerCase())
        );

        // Save the filtered search results to the useState
        setPendingRequestsFiltered(searchResultsPending);
        setRequestHistoryFiltered(searchResultsHistory);

        return;
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get all tasks
    useEffect(() => {

        function onLoadChange(documents) {
            
            // Get all pending requests
            const pending = documents.filter((request) => request.status === 'In Progress');
            const history = documents.filter((request) =>
                currentFilter !== 'All' ? request.status === currentFilter : request.status !== 'In Progress'
            );

            setPendingRequests(pending);
            setRequestHistory(history);

            // Apply search filter if searchInput has a value
            if (searchInput.length > 0) {
                setPendingRequestsFiltered(
                    pending.filter((object) =>
                        object?.requestid?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        object?.taskname?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        object?.createdby?.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        object?.taskdescription?.toLowerCase().includes(searchInput.toLowerCase())
                    )
                );

                setRequestHistoryFiltered(
                    history.filter((object) =>
                        object?.requestid?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        object?.taskname?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        object?.createdby?.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
                        object?.taskdescription?.toLowerCase().includes(searchInput.toLowerCase())
                    )
                );
            } else {
                // Reset filters if no search input
                setPendingRequestsFiltered(pending);
                setRequestHistoryFiltered(history);
            }

            setPageStatus('onload');
        }

        function onError(error) {

            console.log(error);

            //TODO - test this part
            setPageStatus('error-fatal');

        }

        const unsubscribe = QueryListener('tasks', [
            ['assignedto.email', '==', getUser.emailaddress],
            ['tasktype', 'in', ['Line Manager Approval', 'Finance Manager Approval', 'Commercial Second Round Approval', 'Corporate Approval']]
        ], onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        }

        // eslint-disable-next-line
    }, [currentFilter])

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div className='flex flex-col w-full'>

            <PageComponent
                status={pageStatus}
                header={
                    //------------------------------------------------------
                    //  Header
                    //------------------------------------------------------ 
                    <div className='flex flex-col'>
                        <div className='flex flex-row bg-[#E3F1FB] h-16 w-full p-3'>
                            <img className='h-6' src={ArrowIcon} alt='Back' onClick={() => navigate('/account')} />
                            <h5 className='flex items-center h-full cursor-default m-auto'>Approvals</h5>
                        </div>

                        <div className='flex flex-row bg-neutral-100 h-16 w-full px-4 py-2 overflow-hidden' hidden>

                            {/* KC TO DO - Confirm with Ellycia what this does */}
                            <select className='Input-Field-Select w-full'>
                                <option>My Approvals</option>
                            </select>

                        </div>
                    </div>
                }
                body={
                    <div className='flex flex-col'>

                        {/* ======== Search Bar ======== */}
                        <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] mx-4 mt-4 mb-2 rounded-[5px] border-1 border-solid border-[#dee2e6]'>
                            <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                            <label htmlFor='searchInput'>
                                <input
                                    id='searchInput'
                                    className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                    type='text'
                                    placeholder='Search'
                                    onChange={(e) => handleSearch(e.target.value)}
                                    value={searchInput}
                                    autoComplete='no'
                                ></input>
                            </label>
                        </div>

                        <TabView

                            //------------------------------------------------------
                            //  PENDING REQUESTS
                            //------------------------------------------------------

                            defaultView={1}
                            oneTitle={'Pending'}
                            oneTag={pendingRequests?.length}
                            oneContent={

                                <div className='flex flex-col gap-4 p-4'>

                                    {
                                        // No approvals found
                                        pendingRequestsFiltered?.length === 0 ?
                                            (
                                                <p className='px-3'>
                                                    {pendingRequests?.length > 0 ? 'No approvals found.' : 'You have no pending approvals.'}
                                                </p>
                                            ) : (
                                                // Approval cards
                                                pendingRequestsFiltered?.map((request, index) => (
                                                    <div key={index} className='shadow px-3 py-3 rounded-md flex flex-col'>

                                                        {/* Short Description */}
                                                        <div className='flex flex-row justify-between'>
                                                            <label className='font-semibold'>Short Description</label>
                                                            <StatusLabel status={request?.status} />
                                                        </div>
                                                        <p className='line-clamp-2'>{request?.taskdescription}</p>

                                                        <hr></hr>

                                                        {/* Request ID */}
                                                        <div className='flex flex-row justify-between h-4'>
                                                            <label className='font-semibold'>Request ID</label>
                                                            <p>{request?.requestid?.toUpperCase()}</p>
                                                        </div>

                                                        <hr />

                                                        {/* Task Name */}
                                                        <div className='flex flex-row justify-between h-4'>
                                                            <label className='font-semibold'>Name</label>
                                                            <p>{request?.taskname}</p>
                                                        </div>

                                                        <hr />

                                                        {/* Created Date */}
                                                        <div className='flex flex-row justify-between h-4'>
                                                            <label className='font-semibold'>Created</label>
                                                            {/* Get just the date */}
                                                            <p>{ConvertDate(request?.createddate).split(',')[0]}</p>
                                                        </div>

                                                        <br />

                                                        {/* Navigate to Approval Details Page */}
                                                        <button className='Secondary-Button' onClick={() => navigate(`/approvals/${request?.taskid}`)}> View Details</button>

                                                    </div>
                                                ))
                                            )
                                    }

                                </div>

                            }

                            //------------------------------------------------------
                            //  REQUEST HISTORY
                            //------------------------------------------------------

                            twoTitle={'History'}
                            twoContent={

                                <div className='flex flex-col p-4 gap-4'>

                                    {/* ======== Status (Buttons) ========== */}
                                    <div className='flex flex-row items-center gap-2 h-2'>

                                        {/* Approved */}
                                        <button className={currentFilter === 'Completed' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Completed')}>
                                            Approved
                                        </button>

                                        {/* Rejected */}
                                        <button className={currentFilter === 'Rejected' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Rejected')}>
                                            Rejected
                                        </button>

                                        {/* All */}
                                        <button className={currentFilter === 'All' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('All')}>
                                            All
                                        </button>

                                    </div>

                                    {
                                        // No approvals found
                                        requestHistoryFiltered?.length === 0 ?
                                            (
                                                <p className='px-3'>
                                                    No approvals found.
                                                </p>
                                            )
                                            :
                                            (
                                                // Approval cards
                                                requestHistoryFiltered?.map((request, index) => (
                                                    <div key={index} className='shadow px-3 py-3 rounded-md flex flex-col'>

                                                        {/* Short Description */}
                                                        <div className='flex flex-row justify-between'>
                                                            <label className='font-semibold'>Short Description</label>
                                                            <StatusLabel status={request?.status} />
                                                        </div>
                                                        <p className='line-clamp-2'>{request?.taskdescription}</p>

                                                        <hr />

                                                        {/* Request ID */}
                                                        <div className='flex flex-row justify-between h-4'>
                                                            <label className='font-semibold'>Request ID</label>
                                                            <p>{request?.requestid?.toUpperCase()}</p>
                                                        </div>

                                                        <hr />

                                                        {/* Task Name */}
                                                        <div className='flex flex-row justify-between h-4'>
                                                            <label className='font-semibold'>Name</label>
                                                            <p>{request?.taskname}</p>
                                                        </div>

                                                        <hr />

                                                        {/* Created Date */}
                                                        <div className='flex flex-row justify-between h-4'>
                                                            <label className='font-semibold'>Created</label>
                                                            {/* Get just the date */}
                                                            <p>{ConvertDate(request?.createddate).split(',')[0]}</p>
                                                        </div>

                                                        <br />

                                                        {/* Navigate to Approval Details Page */}
                                                        <button className='Secondary-Button' onClick={() => navigate(`/approvals/${request?.taskid}`)}>View Details</button>

                                                    </div>
                                                ))
                                            )
                                    }

                                </div>}
                        ></TabView>
                    </div>}
            ></PageComponent>

        </div>
    )
}