//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// Contexts
import { GetAppSize, GetUser } from '../../Library/GlobalContexts.js';

//Components
import Logout from '../../Library/Logout';
import NavItem from './NavItem';

//Images
import LLLogo from '../Images/Icon_LLLogo.svg';
import LLLogoMobile from '../Images/Icon_LLLogo_Green.svg';
import Menu from '../Images/Icon_Menu_White.svg';
import IconCart from '../Images/Icon_Cart_White.svg';


//CSS
import './Navbar.css';


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const getAppSize = useContext(GetAppSize);
  const routerUseLocation = useLocation();

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

  // Used to change between > 'pending', 'success'
  const [pageStatus, setPageStatus] = useState('pending');

  // If the menu is open or closed
  const [menuOpen, setMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //Returns the style of each component
  function changeStyleHandler(link, textColour) {

    if (textColour === undefined) {

      textColour = 'text-black'

    }

    //Exception for the default route '/' > We want to the home icon to be selected
    if (routerUseLocation.pathname === '/' && link.toLowerCase() === '/home') {

      //Update the style to clicked
      return `cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 px-[10px] py-[14px] no-underline ${textColour}`;

      //Clicked item
    } else if (link.toLowerCase() === (routerUseLocation.pathname).toLowerCase()) {

      //Update the style to clicked
      return `cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 px-[10px] py-[14px] no-underline ${textColour}`;

      //Update the style to NOT clicked
    } else {

      return `cursor-pointer p-[10px] no-underline  ${textColour}`;

    }

  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // OnLoad
  useEffect(() => {

    if (getUser?.roles !== undefined) {
      setPageStatus('success')
    }

  }, [pageStatus, getUser])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='flex flex-row items-center justify-between bg-[#141C25] text-[white] px-[25px] py-0 h-[70px]'>

      {/* ---------------------------------------------------- */}
      {/*  Nav Header - Desktop                                */}
      {/* ---------------------------------------------------- */}

      <div className='flex flex-row items-center justify-between p-0'>

        {/* ------------------------- */}
        {/*  Logo                     */}
        {/* ------------------------- */}

        {
          getAppSize.width > 768 &&

          <div className='flex items-center'>

            <NavLink to='/'>
              <img className='mr-[20px] cursor-pointer' src={LLLogo} alt='Lendlease Logo' height='64px'></img>
            </NavLink>

          </div>
        }

        {/* ------------------------- */}
        {/*  Links                    */}
        {/* ------------------------- */}

        <div>

          {
            getAppSize.width > 768 &&

            <div className='flex flex-row items-center'>

              {/* Store */}
              <NavItem style={changeStyleHandler('/', 'text-white')} link={'/'} title={'Store'} roles={['isLLUser']}></NavItem>

              {/* Support */}
              <NavItem style={changeStyleHandler('/support', 'text-white')} link={'/support'} title={'Support'} roles={['isLLUser']}></NavItem>

            </div>
          }

          </div>

      </div>

      {/* ------------------------- */}
      {/*  Nav Menu                 */}
      {/* ------------------------- */}

      {/* Show menu (Small Device) or signout button (Large Device) */}
      {
        getAppSize.width <= 768 ? 

        // MOBILE
        (
          <div>

            {/* Hamburger Menu  (Left) */}
            <img className='w-[28px] absolute top-[20px] left-[10px] cursor-pointer' onClick={() => setMobileMenuOpen(!mobileMenuOpen)} src={Menu} alt='Menu'></img>

            {/* Logo */}
            <NavLink to='/'>
              <img className='absolute left-[60px] top-[16px] cursor-pointer' src={LLLogoMobile} alt='Lendlease Logo' height='64px'></img>
            </NavLink>

            {/* Cart  (Right) */}
            <img className='w-[28px] absolute top-[20px] right-[60px] cursor-pointer' src={IconCart} alt='icon-cart'></img>

            {/* Avatar / Profile icon (Right) */}
            <label className='absolute top-[16px] right-[10px] cursor-pointer bg-[#8ECDCA] text-[#141c25ec] p-[7px] rounded-[50%] text-md w-[35px] h-[35px] flex justify-center items-center' onClick={() => setMenuOpen(!menuOpen)}>
              {getUser?.givenname[0]}{getUser?.surname[0]}
            </label>  

            {/* ------------------------- */}
            {/* Dropdown Menus            */}
            {/* ------------------------- */}

            {/* Hamburger Menu Dropdown (Left) */}
            <div onClick={() => setMenuOpen(!mobileMenuOpen)} hidden={!mobileMenuOpen} className='flex flex-column absolute top-[69px] left-[0px] bg-[#141c25ec] w-[220px] p-2 rounded-b animate-menuDrop'>

              {/* Store */}
              <NavItem style={changeStyleHandler('/', 'text-white')} link={'/'} title={'Store'} roles={['isLLUser']}></NavItem>

              {/* Purchases */}
              <NavItem style={changeStyleHandler('/purchases', 'text-white')} link={'/purchases'} title={'Purchases'} roles={['isLLUser']}></NavItem>

              {/* Support */}
              <NavItem style={changeStyleHandler('/support', 'text-white')} link={'/support'} title={'Support'} roles={['isLLUser']}></NavItem>

              {/* Log out Button */}
              <div className='flex flex-row-reverse'>
                <Logout></Logout>
              </div>

            </div>

            {/* Profile Dropdown (Right) */}
            <div onClick={() => setMenuOpen(!menuOpen)} hidden={!menuOpen} className='flex flex-column absolute top-[69px] right-[0px] bg-[#141c25ec] w-[220px] p-2 rounded-b animate-menuDrop'>

              {/* Account */}
              <NavItem style={changeStyleHandler('/', 'text-white')} link={'/account'} title={'Account'} roles={['isLLUser']}></NavItem>

            </div>

          </div>
        )
        : 
        // DESKTOP
        (
          <div className='flex w-[210px] justify-end'>
            <img className='w-[28px] mr-[20px] cursor-pointer' src={IconCart} alt='icon-cart'></img>

            {/* Avatar / Profile Icon (Right) */}
            <label className='cursor-pointer bg-[#8ECDCA] text-[#141c25ec] p-[7px] rounded-[50%] text-md w-[35px] h-[35px] flex justify-center items-center' onClick={() => setMenuOpen(!menuOpen)}>
              {getUser.givenname[0]}{getUser.surname[0]}
            </label>

            {/* Profile Dropdown (Right) */}
            <div hidden={!menuOpen} className='flex flex-column gap-0 p-0 absolute top-[70px] right-[0px] bg-[#141c25ec] w-[150px] rounded-b shadow-lg'>
                
                <NavLink to='/approvals'>
                  <label className='mr-[10px] text-white cursor-pointer py-[10px] px-[12px]' onClick={() => setMenuOpen(false)}>
                    My Account
                  </label>
                </NavLink>

                <hr className='m-0 p-0'></hr>
                
                <Logout></Logout>
              
            </div> 

          </div>

        )

      }
    </div>
  )

}
