//================================================================
//  Page: Purchases 
//================================================================

//  Purpose: Purchases page for this application

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors } from '../../Library/GlobalContexts';

//Components

//Functions

//Images

//CSS
import './Purchases.css';


export default function Purchases() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

  const navigate = useNavigate();
  const getUser = useContext(GetUser);
  const setAppErrors = useContext(SetAppErrors);


  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='h-full flex flex-col gap-2 bg-gray-50 px-[3%] py-[2%]'>

      Purchases placeholder

    </div>
    )
}