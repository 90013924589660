//Libraries
// eslint-disable-next-line
import React, { useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged, OAuthProvider } from 'firebase/auth';

//Functions
import GetDocument from './GetDocument';
import DocumentListener from './DocumentListener';

//Contexts
import {SetFireBaseProvider, SetFireBaseUser, GetUser, SetUser, GetAppStatus, SetAppStatus, SetAppErrors} from './GlobalContexts';

/**
 * Handles any auth interactions with FireBase and
 * saves all interactions to useContexts/session storage.
 * 
 * @param {object} children
 * 
 * ```
 * ReactDOM.render(
 *     <UserContexts>
 *       <AuthProvider>
 *         <LoginHandler></LoginHandler>
 *       </AuthProvider>
 *     </UserContexts>,
 *   document.getElementById('root')
 * );
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/web/setup
 */

export default function AuthProvider({children}) {

  //------------------------------------------------------
  //  Firebase
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setFirebaseUser = useContext(SetFireBaseUser);
    const setUser = useContext(SetUser);
    const getUser = useContext(GetUser);
    const setFireBaseProvider = useContext(SetFireBaseProvider);
    const getAppStatus = useContext(GetAppStatus);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  Find the users current auth state when the apps auth state changes
  //------------------------------------------------------

    useEffect(() => {

      onAuthStateChanged(auth, (user) =>{

        //Successful sign in 
        if (user){

          //------------------------------------------------------
          //  Save Firebase profile to useContext
          //------------------------------------------------------

          setFirebaseUser(user);

          //------------------------------------------------------
          //  Get user document, it SHOULD exist!
          //------------------------------------------------------

          GetDocument('users', user.email, true).then((document) => {

            if (document === undefined) {

              setAppErrors(`User account doesn't exist.`);
              return setAppStatus('failed');
            
            }

            // Save user doc
            setUser(document);

            // Access Checks
            if (document.status === 'inactive') return setAppStatus('accessdenied');
            if (document.roles.csmUser === true) return setAppStatus('authenticated');
            if (document.roles.itUser === true) return setAppStatus('authenticated');

            // Catch all
            setAppStatus('accessdenied');

          }).catch(() =>{

            setAppErrors(`bad-network-connection`);
            setAppStatus('failed');

          });
       
        // Catch if the app any other state than 'unauthenticated' 
        } else if (getAppStatus !== 'unauthenticated') {

          setAppStatus('unauthenticated');

        }

      });

    // eslint-disable-next-line
    }, []);

    //Save the auth state to session storage
    //This allows us to presist data after refreshes
    useEffect(() => {

      sessionStorage.setItem('getAppStatus', getAppStatus);

    }, [getAppStatus]);

  //------------------------------------------------------
  //  Listens for any changes to to the user collection
  //  Update the user context with current data
  //------------------------------------------------------
  
    useEffect(() => {
      
      if (getAppStatus !== 'authenticated') return;
      if (getUser === undefined) return;

      function onLoadChange(document){

        // Save user doc
        setUser(document);

        // Access Checks
        if (document.status === 'inactive') return setAppStatus('accessdenied');
        if (document.roles.csmUser === true) return setAppStatus('authenticated');
        if (document.roles.itUser === true) return setAppStatus('authenticated');

        // Catch all
        setAppStatus('accessdenied');

      }

      // Handle errors
      function onError(error){

        // User not in Azure AD - this is an issue, so immediately error out
        setAppStatus('failed');
        setAppErrors(error);

      }

      const unsubscribe = DocumentListener('users', getUser.userid, onLoadChange, onLoadChange, onError);

      // Remove document listener if the component 'unMounts'
      return () =>{

        unsubscribe();

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAppStatus]);

  //------------------------------------------------------
  //  Define firebase OAuthProvider > 'microsoft.com'
  //------------------------------------------------------

    useEffect(() => {

      //We want to use the 'OAuthProvider' > 'microsoft.com'
      const Provider = new OAuthProvider('microsoft.com');
      Provider.setCustomParameters(({
        tenant: process.env.REACT_APP_FIREBASE_AZURE_TENANT_ID,
      }));

      //Add scopes
      Provider.addScope('email');
      Provider.addScope('openid');
      Provider.addScope('profile');
      Provider.addScope('User.Read');

      //Save to useContext
      setFireBaseProvider(Provider);

    }, [setFireBaseProvider]);

  //------------------------------------------------------
  //  Pass down all Parent components to childern
  //------------------------------------------------------

    return children;

  //------------------------------------------------------
}
