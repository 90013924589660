//================================================================
//  Component: Footer
//================================================================

//  Purpose: This is the page footer, which includes the LL privacy policy link

//  Example:
//    <Footer></Footer>    

//================================================================

//Libraries
import React from 'react';


export default function Footer() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='flex flex-col md:flex-row justify-between items-center relative bottom-0 w-full h-auto md:h-[60px] py-3 px-4 bg-[#141C25] text-white text-[15px]'>
      <a
        className='text-white no-underline hover:underline mb-2 md:mb-0'
        href='https://www.lendlease.com/au/privacy/'
        target='_blank'
        rel='noopener noreferrer'
      >
        Privacy Policy
      </a>

      <div className='flex flex-row gap-2 text-center'>
        © Lendlease Corporation, all rights reserved.
      </div>
    </div>

  )
}
